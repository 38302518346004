<template>
  <v-card class="delete-small-confirmation">
    <v-card-title> Confirmation </v-card-title>
    <v-card-text class="px-8 py-4 font-size-16 custom-border-bottom">
      <p class="m-0 font-weight-600" v-html="message"></p>
    </v-card-text>
    <v-card-actions class="p-4">
      <v-spacer></v-spacer>
      <v-btn
        x-small
        v-on:click="$emit('cancel', true)"
        class="mx-0 mr-2 custom-grey-border custom-bold-button"
      >
        No, Cancel
      </v-btn>
      <v-btn
        v-on:click="$emit('success', true)"
        class="mx-0 custom-bold-button white--text"
        color="red lighten-1"
        x-small
      >
        Yes, Delete
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "Are you sure, you want to delete this ?",
    },
  },
};
</script>
