<template>
  <v-container fluid class="pb-0">
    <v-layout class="visits-search-bar" v-if="detailType != 'tickets'">
      <template v-if="selectedRows.length > 0">
        <v-flex class="pt-0">
          <h1
            class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
          >
            {{ totalSelected }} Selected
          </h1>
        </v-flex>
        <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
          <v-btn
            :disabled="pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            color="cyan lighten-1  white--text"
            v-on:click="reassignScheduleDialog"
          >
            Bulk Assign
          </v-btn>
          <v-btn
            :disabled="pageLoading"
            class="mx-2 custom-grey-border custom-bold-button"
            color="red lighten-1 white--text"
            v-on:click="clearSelections"
          >
            <v-icon dark left>mdi-close</v-icon>Clear Selections
          </v-btn>
        </v-flex>
      </template>
      <template v-else>
        <v-flex md="2" class="pb-2 mr-2 mxw-200">
          <v-text-field
            v-model.trim="filter_search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search"
            hide-details
            solo
            :loading="pageLoading"
            :disabled="pageLoading"
            color="cyan"
            @keydown.enter="getVisits()"
            @keydown.tab="getVisits()"
            v-on:change="getVisits()"
          ></v-text-field>
        </v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200">
          <v-menu
            v-model="datePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                filled
                flat
                placeholder="Delivery Date Range"
                hide-details
                solo
                :loading="pageLoading"
                :disabled="pageLoading"
                clearable
                prepend-inner-icon="mdi-calendar"
                v-on:click:clear="clearFilter('dates')"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="formattedDate"
                color="cyan"
              ></v-text-field>
            </template>
            <v-date-picker
              color="cyan"
              range
              :disabled="pageLoading"
              v-on:change="getVisits"
              v-model="dates"
            ></v-date-picker>
          </v-menu>
        </v-flex>
        <!--  <v-flex md="2" class="pb-2 mx-2 mx-width-400"  v-if="detailRoute != 'follow-up.detail'">
        <v-autocomplete
         
          hide-details
          v-model.trim="filter_status"
          :items="statusList"
          dense
          flat
          filled
          placeholder="Delivery Status"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading"
          :loading="pageLoading"
          v-on:change="getVisits"
          item-value="status"
          item-text="text"
          class="filter-select"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Status(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-action class="ma-0">
              <v-chip
                style="height: 10px; width: 10px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content class="py-0">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
              >
                <template v-if="item.value == 'all'"
                  >{{ item.text }} ({{ item.all_ticket_visits }})</template
                >
                <template v-else
                  >{{ item.text }} ({{
                    item.ticket_visit_status_count
                  }})</template
                >
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action class="mr-0">
              <v-chip
                style="height: 16px; width: 16px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action class="align-self-center">
              <v-list-item-subtitle
                class="text-lowercase font-weight-500 font-size-14"
              >
                <template v-if="item.value == 'all'">{{
                  item.all_ticket_visits
                }}</template>
                <template v-else>{{
                  item.ticket_visit_status_count
                }}</template></v-list-item-subtitle
              >
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-flex> -->
        <v-flex md="2" class="pb-2 mr-2 mxw-200">
          <v-autocomplete
            hide-details
            v-if="!internal"
            v-model.trim="filter_customer"
            clearable
            :items="customerList"
            dense
            flat
            filled
            placeholder="Customer"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="pageLoading || customerLoading"
            :loading="customerLoading || pageLoading"
            item-text="display_name"
            item-value="id"
            v-on:change="
              getPropertyList();
              getVisits();
            "
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Customer(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar>
                <v-img
                  :lazy-src="$defaultProfileImage"
                  :src="$assetAPIURL(item.profile_logo)"
                  aspect-ratio="1"
                  class="margin-auto"
                  transition="fade-transition"
                ></v-img>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.display_name }}</v-list-item-title
                >
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.company_name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action
                v-if="item.default_person"
                class="align-self-center"
              >
                <v-list-item-subtitle
                  class="text-lowercase pb-2 font-weight-500 font-size-14"
                  >{{ item.default_person.primary_email }}</v-list-item-subtitle
                >
                <v-list-item-subtitle
                  class="text-lowercase font-weight-500 font-size-14"
                  >{{ item.default_person.primary_phone }}</v-list-item-subtitle
                >
              </v-list-item-action>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200">
          <v-autocomplete
            hide-details
            v-if="!internal"
            clearable
            v-model.trim="filter_property"
            :items="propertyList"
            dense
            flat
            filled
            placeholder="Site Location"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="pageLoading || propertyLoading"
            :loading="propertyLoading || pageLoading"
            item-text="barcode"
            item-value="id"
            v-on:change="getVisits"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Site Location(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-home-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-ellipsis max-width-400px">
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.barcode }} ({{ getFormattedProperty(item) }})
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200">
          <v-autocomplete
            hide-details
            v-if="!internal"
            clearable
            v-model.trim="filter_engineer"
            :items="engineerList"
            dense
            flat
            filled
            placeholder="Driver"
            item-color="cyan"
            color="cyan"
            solo
            :disabled="pageLoading || engineerLoading"
            :loading="engineerLoading || pageLoading"
            item-text="display_name"
            item-value="id"
            v-on:change="getVisits"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Engineer(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-avatar
                height="30"
                min-width="30"
                width="30"
                class="mr-1 my-1 grey lighten-2 grey--text"
              >
                <v-img
                  v-if="item.profile_logo != null"
                  src="item.profile_logo"
                ></v-img>
                <span v-else class="fw-600 mb-1">{{
                  item.display_name.slice(0, 2).toUpperCase()
                }}</span>
              </v-list-item-avatar>
              <v-list-item-content class="text-ellipsis max-width-400px">
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.display_name }}
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action
                class="align-self-center"
                v-if="filter_engineer == item.id"
              >
                <v-icon color="cyan">mdi-check</v-icon>
              </v-list-item-action>
            </template>
          </v-autocomplete>
        </v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200"></v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200"></v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200"></v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200"></v-flex>
        <v-flex md="2" class="pb-2 mr-2 mxw-200"></v-flex>
        <div class="pr-0 pb-2 d-flex">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                depressed
                :disabled="pageLoading"
                v-on:click="getVisits"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          <v-tooltip
            v-if="!internal && detailRoute != 'follow-up.detail'"
            top
            content-class="custom-top-tooltip"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button custom-btn-color white--text"
                tile
                depressed
                :disabled="pageLoading"
                v-on:click="createJob()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Create Delivery</span>
          </v-tooltip>
          <!-- {{ $route.query.tab }} -->

          <v-btn
            v-if="detailRoute != 'follow-up.detail'"
            :disabled="pageLoading"
            :type-id="$route.query.tab"
            class="mx-2 custom-bold-button"
            v-on:click="exportQuotation"
            color="cyan white--text"
          >
            <v-icon dark left>mdi-database-export</v-icon> Export
          </v-btn>
          <v-menu
            max-height="400"
            max-width="250"
            offset-y
            left
            :close-on-content-click="false"
            content-class="white-background"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :disabled="pageLoading"
                class="mx-1 custom-bold-button btn-tab-hide"
                color="cyan white--text"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon dark>mdi-table-edit</v-icon>
              </v-btn>
            </template>
            <draggable
              tag="ul"
              v-model="defaultColDefs"
              v-on:change="updateVisible()"
              class="draggable-group"
              handle=".draggable-drag-icon"
            >
              <template v-for="cols in defaultColDefs">
                <li
                  class="draggable-group-item"
                  :key="cols.field"
                  v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                >
                  <v-checkbox
                    dense
                    v-model="defaultColShow"
                    v-bind:value="cols.field"
                    :label="cols.headerName"
                    :disabled="Number(cols.fixed) || pageLoading"
                    color="cyan"
                    hide-details
                    class="mt-0 mb-0"
                    v-on:change="updateVisible()"
                  ></v-checkbox>
                  <v-icon class="draggable-drag-icon" right color="cyan"
                    >mdi-drag</v-icon
                  >
                </li>
              </template>
            </draggable>
          </v-menu>
        </div>
      </template>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 90vh; position: relative"
    >
      <!-- <pre>{{defaultColDefs}}</pre> -->
      <ListingTable
        :columnCount="columnLength()"
        :dataLoading="pageLoading"
        :rowData="rowData"
      >
        <template v-slot:thead>
          <thead>
            <tr>
              <template v-for="cols in customThead">
                <th
                  v-if="cols.visible"
                  :key="cols.id"
                  class="simple-table-th"
                  :class="{
                    'checkbox-width-limit': cols.checkbox,
                    'pointer-events-none': lodash.isEmpty(rowData),
                  }"
                >
                  <template v-if="cols.checkbox && Number(cols.checkbox) == 1">
                    <v-checkbox
                      dense
                      :disabled="pageLoading"
                      v-model="selectedAll"
                      color="cyan"
                      class="hide-margin"
                      hide-details
                    ></v-checkbox>
                  </template>
                  <template v-else>
                    <div
                      v-if="cols.sortable"
                      class="simple-table-text sortable"
                      v-on:click="
                        !pageLoading
                          ? updateSorting({
                              field: cols.field,
                              sort: cols.sort,
                            })
                          : 'javascript:void(0)'
                      "
                    >
                      {{ cols.headerName }}
                    </div>
                    <div v-else class="simple-table-text">
                      {{ cols.headerName }}
                    </div>

                    <template v-if="cols.sort">
                      <v-icon
                        class="simple-table-sort"
                        v-if="cols.sort == 'asc'"
                        small
                        right
                        color="cyan"
                        >mdi-sort-ascending</v-icon
                      >
                      <v-icon
                        class="simple-table-sort"
                        v-if="cols.sort == 'desc'"
                        small
                        right
                        color="cyan"
                        >mdi-sort-descending</v-icon
                      >
                    </template>
                  </template>
                </th>
              </template>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody>
          <tbody class="custom-border-bottom">
            <template v-if="!lodash.isEmpty(rowData)">
              <tr
                v-for="(row, index) in rowData"
                :key="index"
                link
                class="customer-listing-row"
              >
                <template v-for="cols in customThead">
                  <td
                    v-if="cols.visible"
                    :key="cols.visit_id"
                    class="font-size-14 font-weight-500"
                    :class="{
                      'simple-table-td': !cols.checkbox,
                      'checkbox-width-limit': cols.checkbox,
                    }"
                    v-on:click="
                      cols.checkbox == '0'
                        ? $router.push(
                            getDefaultRoute('delivery.detail', {
                              params: { id: row.visit_id },
                            })
                          )
                        : 'javascript:void(0)'
                    "
                  >
                    <template
                      v-if="cols.checkbox && Number(cols.checkbox) == 1"
                    >
                      <v-checkbox
                        dense
                        v-model="selectedRows"
                        v-bind:value="Number(row.visit_id)"
                        color="cyan"
                        :disabled="
                          row.visit_status == 2 || row.visit_status == 7
                            ? true
                            : false
                        "
                        :readonly="
                          row.visit_status == 2 || row.visit_status == 7
                            ? true
                            : false
                        "
                        class="hide-margin"
                        hide-details
                      ></v-checkbox>
                    </template>
                    <template v-else-if="cols.field == 'barcode'">
                      <v-chip
                        label
                        dense
                        small
                        color="chip-custom-blue"
                        outlined
                        class="font-weight-600 custom-barcode"
                        >{{ row.visit_barcode }}
                      </v-chip>
                    </template>
                    <template v-else-if="cols.field == 'property'">
                      <div class="job-listing-customer">
                        <p class="m-0 custom-nowrap-ellipsis-two-line">
                          <b>Delivery Title: </b>{{ row.visit_title }}
                        </p>

                        <p
                          class="m-0 custom-nowrap-ellipsis-two-line"
                          v-if="row.property_address"
                        >
                          <span
                            content="Delivery Location"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                          >
                            <b>
                              <v-icon
                                style="font-size: 18px; margin-top: -3px"
                                color="#000"
                              >
                                mdi-home-map-marker
                              </v-icon>
                            </b>
                            {{ row.property_address }}
                          </span>
                        </p>
                      </div>
                    </template>
                    <template v-else-if="cols.field == 'pic_address'">
                      <div class="job-listing-customer">
                        <p
                          class="m-0 custom-nowrap-ellipsis-two-line"
                          v-if="row.property_address"
                        >
                          <span
                            content="Pickup Address"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                          >
                            <b>
                              <v-icon
                                style="font-size: 18px; margin-top: -3px"
                                color="#000"
                              >
                                mdi-home-map-marker
                              </v-icon>
                            </b>
                            {{ getFormattedBilling(row) }}
                          </span>
                        </p>
                      </div>
                    </template>
                    <template v-else-if="cols.field == 'customer'">
                      <div class="job-listing-customer">
                        <p class="m-0">
                          <span
                            class="m-0 text-truncate"
                            content="Contact Person"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                          >
                            <b>
                              <v-icon style="font-size: 16px" color="#000">
                                mdi-account
                              </v-icon>
                            </b>
                            {{
                              row.ticket_attention
                                ? row.ticket_attention
                                : row.customer_display_name
                            }}
                          </span>
                          <v-chip
                            label
                            small
                            color="green white--text"
                            class="d-inline mx-2"
                            v-if="row.is_property_tenant"
                            >Tenant</v-chip
                          >
                        </p>
                        <p class="m-0">
                          <span
                            class="m-0 text-truncate"
                            content="Company Name"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                          >
                            <b>
                              <v-icon style="font-size: 16px" color="#000">
                                mdi-bag-checked
                              </v-icon>
                            </b>
                            {{ row.customer_company_name }}
                          </span>
                        </p>

                        <p class="m-0">
                          <span
                            class="m-0 text-truncate"
                            content="Site Location Contact Person Email"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                          >
                            <b>
                              <v-icon style="font-size: 16px" color="#000">
                                mdi-email
                              </v-icon>
                            </b>
                            <template v-if="row && row.property_primary_email">
                              {{ row.property_primary_email }}
                            </template>
                            <template v-else>
                              <em class="text-muted"> no email</em>
                            </template>
                          </span>
                        </p>
                        <p class="m-0">
                          <span
                            class="m-0 text-truncate"
                            content="Site Location Contact Person Phone"
                            v-tippy="{
                              arrow: true,
                              arrowType: 'round',
                              animation: 'fade',
                            }"
                          >
                            <b>
                              <v-icon style="font-size: 16px" color="#000">
                                mdi-phone
                              </v-icon>
                            </b>
                            <template v-if="row && row.property_primary_phone">
                              {{ row.property_primary_phone }}
                            </template>
                            <template v-else>
                              <em class="text-muted"> no phone</em>
                            </template>
                          </span>
                        </p>
                      </div>
                    </template>
                    <template v-else-if="cols.field == 'started_at'">
                      <p class="m-0 max-content-width text-truncate">
                        <span class="d-block">
                          {{ formatDate(row.visit_started_at) }}

                          <br />
                          <!--  {{ formatDate(row.visit_finished_at) }} -->
                          {{ formatDateTimeStart(row.visit_started_at) }} -
                          {{ formatDateTimeStart(row.visit_finished_at) }}
                        </span>
                        <span class="d-block">
                          <v-chip
                            class="mt-2 mb-2 font-weight-600 cursor-pointer text-lowercase"
                            color="chip-custom-blue"
                            small
                            outlined
                            label
                          >
                            <template>
                              Due in
                              {{ calculateTotalDuration(row.visit_started_at) }}
                            </template>

                            <!--  <template v-else-if="row.visit_status === 2">
                                  in-progress
                                </template>
                                <template v-else>
                                  <template v-if="row.visit_overdue === 1"
                                    >delayed by
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                  <template v-else-if="row.visit_today === 1"
                                    >ends in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_finished_at
                                      )
                                    }}</template
                                  >
                                  <template v-else
                                    >Due in
                                    {{
                                      calculateTotalDuration(
                                        row.visit_started_at
                                      )
                                    }}</template
                                  >
                                </template> -->
                          </v-chip>
                        </span>
                        <span
                          class="d-block"
                          v-if="row.visit_status == 7 && row.delivery_date"
                        >
                          <v-chip
                            class="font-weight-600 cursor-pointer text-lowercase"
                            color="green"
                            small
                            outlined
                            label
                          >
                            {{ actualDateFormate(row.delivery_date) }}
                          </v-chip>
                        </span>
                      </p>
                    </template>
                    <template v-else-if="cols.field == 'status'">
                      <CustomStatus
                        small
                        :status="statusIntiger(row.visit_status)"
                        endpoint="delivery/status"
                      ></CustomStatus>
                      <Priority
                        small
                        :priority="priortyInteger(row.ticket_priority)"
                      ></Priority>
                    </template>
                    <template v-else-if="cols.field == 'engineers'">
                      <div class="job-listing-customer">
                        <p
                          class="m-0 max-content-width text-truncate font-weight-700"
                          :class="{
                            'text--secondary': lodash.isEmpty(
                              row.visit_engineers
                            ),
                          }"
                        >
                          {{ engineerDisplayText(row.visit_engineers) }}
                        </p>
                      </div>

                      <v-btn
                        v-if="
                          getPermission('visit:delete') && row.visit_status == 5
                        "
                        depressed
                        color="red lighten-1 white--text visit-delete-btn my-auto"
                        v-on:click.stop="deleteVisit(row)"
                      >
                        <v-icon style="font-size: 18px; line-height: 18px"
                          >mdi-delete</v-icon
                        >
                        Delete
                      </v-btn>

                      <v-chip
                        v-if="
                          row.is_rejeced == 1 &&
                          row.visit_engineers &&
                          row.visit_engineers.length == 0
                        "
                        label
                        color="red"
                        outlined
                        text-color=""
                        class="p-3 mr-5 mb-1"
                        small
                        >Re-assignment Pending
                      </v-chip>
                      <div>
                        <v-chip
                          v-if="
                            row.visit_engineers &&
                            row.visit_engineers.length > 0
                          "
                          label
                          color="orange"
                          class="p-3 mr-5"
                          text-color="white"
                          small
                          >Assign</v-chip
                        >
                        <v-chip
                          v-if="
                            row.visit_engineers &&
                            row.visit_engineers.length == 0
                          "
                          label
                          color="#24326d"
                          class="p-3 mr-5"
                          text-color="white"
                          small
                          >Unassign</v-chip
                        >
                      </div>
                    </template>
                  </td>
                </template>
              </tr>
            </template>
            <tr v-else-if="!pageLoading">
              <td :colspan="6" class="py-4">
                <p
                  class="m-0 row-not-found text-center font-weight-500 font-size-16"
                >
                  <img
                    :src="$assetURL('media/error/empty.png')"
                    class="row-not-found-image"
                  />
                  Uhh... There are no delivery at the moment.
                </p>
              </td>
            </tr>
          </tbody>
        </template>
      </ListingTable>
    </perfect-scrollbar>
    <ListingFooter
      :dataLoading="pageLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
    <DeleteDialog :delete-dialog="deleteVisitDialog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ deleteVisitBarcode }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="deleteVisitLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="deleteVisitDialog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="deleteVisitLoading"
          :loading="deleteVisitLoading"
          color="red lighten-1 white--text"
          v-on:click="deleteVisitConfirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <template v-if="entityDialog">
      <EntityDialog
        :entity-dialog="entityDialog"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectEntity="selectEntity"
      ></EntityDialog>
    </template>
    <template v-if="customerDialog">
      <CustomerDialog
        :entity="entity"
        :customerDialog="customerDialog"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomer="selectCustomer"
      ></CustomerDialog>
    </template>
    <template v-if="customerPersonDialog">
      <CustomerPersonDialog
        disabled-auto-select
        :customerPersonDialog="customerPersonDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        v-on:resetAll="resetAll"
        v-on:selectCustomerPerson="selectCustomerPerson"
      ></CustomerPersonDialog>
    </template>
    <template v-if="customerPropertyDialog">
      <CustomerPropertyDialog
        disabled-auto-select
        :customerPropertyDialog="customerPropertyDialog"
        :customer="customer"
        v-on:closeDialog="closeDialog"
        addressType="Delivery"
        v-on:resetAll="resetAll"
        v-on:selectCustomerProperty="selectCustomerProperty"
      ></CustomerPropertyDialog>
    </template>
    <BulkReassignSchedule
      :reassign-schedule-dialog="reassign_dialog"
      v-on:close="reassign_dialog = false"
      :visit-arr="selectedRows"
      v-on:success="getVisits()"
    ></BulkReassignSchedule>
  </v-container>
</template>

<script>
import moment from "moment-timezone";
import { QUERY, DELETE, POST } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import { getConfig, saveData } from "@/core/services/local.service";
/* import Barcode from "@/view/pages/partials/Barcode.vue"; */
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
import ListingFooter from "@/view/pages/partials/Listing-Footer";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import Priority from "@/view/pages/partials/Priority.vue";
import DeleteDialog from "@/view/pages/partials/Delete";
import draggable from "vuedraggable";
import { find, isEmpty, toSafeInteger } from "lodash";
import JwtService from "@/core/services/jwt.service";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import BulkReassignSchedule from "@/view/pages/partials/Bulk-Reassign-Schedule.vue";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "visit-listing-template",
  mixins: [CommonMixin],
  props: {
    visitType: {
      type: String,
      required: true,
      default: "all",
    },
    visitStatus: {
      type: Number,
      required: true,
      default: 0,
    },
    detailRoute: {
      type: String,
      default: null,
    },
    detailType: {
      type: String,
      default: null,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    internal: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    engineerId: {
      type: Number,
      default: 0,
    },
    teamId: {
      type: Number,
      default: 0,
    },
    contractId: {
      type: Number,
      default: 0,
    },
    quotationId: {
      type: Number,
      default: 0,
    },
    invoiceId: {
      type: Number,
      default: 0,
    },
    entityId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    selectedRows() {
      this.rowSelected();
    },
    entityId() {
      this.initInternal();
    },
    customerId() {
      this.initInternal();
    },
    engineerId() {
      this.initInternal();
    },
    teamId() {
      this.initInternal();
    },
    quotationId() {
      this.initInternal();
    },
    invoiceId() {
      this.initInternal();
    },
  },
  components: {
    DeleteDialog,
    /*   Barcode, */
    Priority,
    CustomStatus,
    ListingFooter,
    draggable,
    ListingTable,
    EntityDialog,
    CustomerDialog,
    CustomerPersonDialog,
    CustomerPropertyDialog,
    BulkReassignSchedule,
  },
  data() {
    return {
      totalRows: 0,
      rowsOffset: 0,
      currentPage: 1,
      totalPages: 0,
      confirm_loading: false,
      defaultColDefs: [],
      defaultColShow: [],
      customThead: [],
      selectedRows: [],
      perPage: 15,
      pageLoading: false,
      customerLoading: false,
      propertyLoading: false,
      customerDialog: false,
      entityDialog: false,
      customerPersonDialog: false,
      customerPropertyDialog: false,
      engineerLoading: false,
      isRowLoading: {},
      dates: [],
      statusList: [],
      reassign_dialog: false,
      customerList: [],
      totalSelected: 0,
      propertyList: [],
      engineerList: [],
      rowData: [],
      filter_search: null,
      filter_customer: null,
      filter_engineer: null,
      filter_quotation: null,
      filter_contract: null,
      filter_invoice: null,
      filter_status: null,
      filter_team: null,
      filter_property: null,
      timeout: null,
      timeoutLimit: 500,
      entity: 0,
      customer: 0,
      customerPerson: 0,
      customerProperty: 0,
      entityList: [],
      entityObject: {},
      deleteVisitId: null,
      deleteVisitDialog: false,
      deleteVisitLoading: false,
      deleteVisitBarcode: null,
    };
  },
  mounted() {
    const _this = this;
    _this.initInternal();
    _this.entityList = getConfig("entity");
    _this.entityObject = _this.lodash.head(_this.entityList);
    _this.$nextTick(() => {
      if (!_this.internal || _this.engineerId) {
        _this.getVisits();
        _this.getCustomerList();
        _this.getEngineerList();
      }

      _this
        .getStatusList()
        .then((status) => {
          _this.statusList = status;
        })
        .catch((error) => {
          _this.logError(error);
        });
    });
  },
  methods: {
    reassignScheduleDialog() {
      this.reassign_dialog = true;
    },

    actualDateFormate(date) {
      if (date) {
        let actualDate = moment(date).format("L hh:mm A");
        return actualDate;
      } else {
        return false;
      }
    },
    columnLength() {
      const defaultColDefs = this.lodash.filter(this.defaultColDefs, {
        visible: true,
      });
      if (defaultColDefs) {
        return defaultColDefs.length;
      }
      return 0;
    },
    rowSelected() {
      this.totalSelected = this.selectedRows.length;
    },
    clearSelections() {
      this.selectedRows = [];
    },
    getFormattedBilling(row) {
      let tempArr = [];
      if (row.delivery_unit_no) {
        tempArr.push(row.delivery_unit_no);
      }
      if (row.delivery_street_1) {
        tempArr.push(row.delivery_street_1);
      }
      if (row.delivery_street_2) {
        tempArr.push(row.delivery_street_2);
      }
      if (row.delivery_zip_code) {
        tempArr.push(row.delivery_zip_code);
      }
      if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country.name);
      }
      return tempArr.join(", ");
    },
    statusIntiger(param) {
      return toSafeInteger(param);
    },
    priortyInteger(param) {
      return toSafeInteger(param);
    },
    formatDateTimeStart(row) {
      return moment(row).format("hh:mm A");
    },
    formatetimedata(row) {
      //console.log(row,"row");
      return moment(row).format("hh:mm A");
    },
    routeToQuotation(id) {
      this.$router.push({
        name: "admin.quotation.detail",
        params: { id },
      });
    },
    updateVisible() {
      const _this = this;

      for (let index = 0; index < _this.defaultColDefs.length; index++) {
        const element = _this.defaultColDefs[index];
        _this.defaultColDefs[index].visible = 0;
        if (_this.defaultColShow.includes(element.field)) {
          _this.defaultColDefs[index].visible = 1;
        }
      }

      _this.$nextTick(() => {
        _this.$store
          .dispatch(POST, {
            url: "group",
            data: { tables: this.defaultColDefs },
          })
          .then(({ data }) => {
            this.customThead = data;
          });
      });
    },
    deleteVisit({ visit_id, visit_barcode }) {
      this.deleteVisitId = visit_id;
      this.deleteVisitDialog = true;
      this.deleteVisitBarcode = visit_barcode;
    },
    exportQuotation() {
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "visits/export";
      let tab = this.$route.query.tab;
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      downloadURL.searchParams.append("search", tab);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },

    deleteVisitConfirm() {
      this.deleteVisitLoading = true;
      this.$store
        .dispatch(DELETE, { url: `visit/${this.deleteVisitId}` })
        .then(() => {
          this.deleteVisitId = null;
          this.deleteVisitDialog = false;
          this.deleteVisitBarcode = null;
          this.getVisits();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.deleteVisitLoading = false;
        });
    },
    createJob() {
      /* if (!this.entity) {
        this.entityDialog = true;
      } else */ if (!this.customer) {
        this.customerDialog = true;
      } else if (!this.customerProperty) {
        this.customerPropertyDialog = true;
      }
    },
    initInternal() {
      this.entity = this.lodash.toSafeInteger(this.entityId);
      this.customer = this.lodash.toSafeInteger(this.customerId);
      this.filter_quotation = this.lodash.toSafeInteger(this.quotationId);
      this.filter_contract = this.lodash.toSafeInteger(this.contractId);
      this.filter_invoice = this.lodash.toSafeInteger(this.invoiceId);
      this.filter_engineer = this.lodash.toSafeInteger(this.engineerId);
      this.filter_team = this.lodash.toSafeInteger(this.teamId);
      this.filter_customer = this.customer;
      if (this.filter_team) {
        this.$nextTick(() => {
          this.getVisits();
          this.getStatusList();
        });
      }
      if (this.filter_engineer) {
        this.$nextTick(() => {
          this.getVisits();
          this.getStatusList();
        });
      }
      if (this.entity && this.customer && this.filter_customer) {
        this.$nextTick(() => {
          this.getVisits();
          this.getStatusList();
          this.getPropertyList();
        });
      }
    },
    updateEntity(row) {
      this.entityObject = row;
      this.getVisits();
      this.getCustomerList();
      this.propertyList = [];
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 0;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectProject(param) {
      this.projectId = param;
    },
    selectContact(param) {
      this.contractId = param;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkJob();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkJob();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("delivery.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    calculateTotalDuration(visit_date) {
      if (moment(visit_date).isValid()) {
        let a = moment(visit_date);
        let b = moment();

        let totalDuration = moment.duration(a.diff(b));
        if (b.isAfter(a)) {
          totalDuration = moment.duration(b.diff(a));
        }

        let result = [];

        let years = totalDuration.get("years");
        if (years > 0) {
          if (years == 1) {
            result.push(years + " Year");
          } else {
            result.push(years + " Years");
          }
        }

        let months = totalDuration.get("months");
        if (months > 0) {
          if (months == 1) {
            result.push(months + " Month");
          } else {
            result.push(months + " Months");
          }
        }

        let days = totalDuration.get("days");
        if (days > 0) {
          if (days == 1) {
            result.push(days + " Day");
          } else {
            result.push(days + " Days");
          }
        }

        let hours = totalDuration.get("hours");
        if (hours > 0) {
          if (hours == 1) {
            result.push(hours + " Hour");
          } else {
            result.push(hours + " Hours");
          }
        }

        let minutes = totalDuration.get("minutes");
        if (minutes > 0) {
          if (minutes == 1) {
            result.push(minutes + " Minute");
          } else {
            result.push(minutes + " Minutes");
          }
        }

        return result.join(" ");
      }
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter_search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getVisits();
    },
    getVisits() {
      const _this = this;

      if (_this.pageLoading) {
        return false;
      }

      let filter = {
        daterange: _this.dates,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
        search: _this.lodash.toString(_this.filter_search) || null,
        invoice: _this.lodash.toSafeInteger(_this.filter_invoice) || null,
        quotation: _this.lodash.toSafeInteger(_this.filter_quotation) || null,
        contract: _this.lodash.toSafeInteger(_this.filter_contract) || null,
        customer: _this.lodash.toSafeInteger(_this.filter_customer) || null,
        engineer: _this.lodash.toSafeInteger(_this.filter_engineer) || null,
        status:
          _this.visitStatus > 0
            ? _this.lodash.toSafeInteger(_this.visitStatus)
            : null,
        property: _this.lodash.toSafeInteger(_this.filter_property) || null,
        team: _this.lodash.toSafeInteger(_this.filter_team) || null,
        type: _this.visitType,
      };

      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      }

      _this.rowData = [];

      _this.pageLoading = true;

      let current_page = _this.currentPage;
      let per_page = _this.perPage;
      let module_type = "delivery";

      _this
        .getVisitList({ filter, current_page, per_page, module_type })
        .then((data) => {
          /*  _this.rowData = data.rows;

          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          _this.totalRows = data.total_rows;
          _this.rowsOffset = data.offset;
           */

          _this.rowData = data.rows;
          _this.statusList = data.status_list;
          _this.totalPages = Math.ceil(data.total_rows / data.per_page);
          _this.currentPage = data.current_page;
          let thead = data.theads;
          _this.defaultColDefs = data.theads;
          _this.totalRows = data.total_rows;
          _this.customThead = [];
          for (let index = 0; index < thead.length; index++) {
            let element = thead[index];
            if (element.visible == 1) {
              _this.customThead.push(element);
            }
          }
          let t_out = this.lodash.filter(
            thead,
            (row) => Number(row.visible) == 1
          );
          _this.defaultColShow = this.lodash.map(t_out, "field");
          _this.rowsOffset = data.offset;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
          _this.selectedRows = [];
        });
    },

    checkStartTimeButton(rowData) {
      //console.log(rowData, "rowData");
      if (!isEmpty(rowData)) {
        let VisitAssignData = find(rowData, (row) => row.engineer_active == 1);

        if (VisitAssignData) {
          return "";
        } else {
          return "Unassigned";
        }
      }
    },

    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned.";
      } else {
        return display_name.join(", ");
      }
    },
    getVisitList(data) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "visit",
              data,
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(defaultPerson) {
      if (this.lodash.isEmpty(defaultPerson)) {
        return "";
      }
      return defaultPerson.display_name;
    },
    getFormattedProperty(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      return tempArr.join(", ");
    },
    getDrivers() {
      const _this = this;
      _this.engineerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "driver-list",
            })
            .then(({ data }) => {
              resolve(data);
            })
            .catch((error) => {
              _this.engineerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.engineerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.engineerLoading = false;
          reject(error);
        }
      });
    },
    getCustomers() {
      const _this = this;
      _this.customerLoading = true;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "customer-list",
              data: { entity: _this.entityObject.id, all: true },
            })
            .then(({ data }) => {
              saveData("_btacl_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              _this.customerLoading = false;
              reject(error);
            })
            .finally(() => {
              _this.customerLoading = false;
            });
        } catch (error) {
          _this.logError(error);
          _this.customerLoading = false;
          reject(error);
        }
      });
    },
    getCustomerProperties(customer) {
      const _this = this;
      _this.propertyLoading = true;
      _this.filter_property = null;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "property-list/" + (customer || ""),
              data: { entity: _this.entityObject.id, all: true },
            })
            .then(({ data }) => {
              saveData("_btacpl_" + customer + "_tmp", data);
              resolve(data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.propertyLoading = false;
            });
        } catch (error) {
          _this.propertyLoading = false;
          reject(error);
        }
      });
    },
    getEngineerList() {
      const _this = this;

      _this
        .getDrivers()
        .then((engineer) => {
          _this.engineerList = engineer;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getCustomerList() {
      const _this = this;

      if (_this.customerLoading) {
        return false;
      }

      _this
        .getCustomers()
        .then((customer) => {
          _this.customerList = customer;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.getPropertyList();
        });
    },
    getPropertyList(param) {
      const _this = this;

      if (_this.propertyLoading) {
        return false;
      }

      _this.propertyList = [];
      _this
        .getCustomerProperties(param)
        .then((status) => {
          _this.propertyList = status;
        })
        .catch((error) => {
          _this.logError(error);
        });
    },
    getStatusList() {
      const _this = this;

      let filter = {
        daterange: _this.dates,
        entity: _this.entityObject ? _this.entityObject.id : undefined,
        search: _this.lodash.toString(_this.filter_search) || null,
        invoice: _this.lodash.toSafeInteger(_this.filter_invoice) || null,
        quotation: _this.lodash.toSafeInteger(_this.filter_quotation) || null,
        customer: _this.lodash.toSafeInteger(_this.filter_customer) || null,
        engineer: _this.lodash.toSafeInteger(_this.filter_engineer) || null,
        status: _this.lodash.toSafeInteger(_this.filter_status) || null,
        property: _this.lodash.toSafeInteger(_this.filter_property) || null,
        type: _this.visitType,
      };

      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, { url: "delivery/status", data: filter })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  computed: {
    showingFrom() {
      return this.lodash.isEmpty(this.rowData) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      let rowData = this.lodash.map(this.rowData, function (row) {
        if (!row.header) return row;
      });

      rowData = this.lodash.without(rowData, undefined);

      return this.rowsOffset + (rowData ? rowData.length : 0);
    },
    selectedAll: {
      set(param) {
        this.selectedRows = [];
        if (param && this.rowData.length) {
          for (let i = this.rowData.length - 1; i >= 0; i--) {
            if (
              this.rowData[i].visit_status != 7 &&
              this.rowData[i].visit_status != 2
            ) {
              this.selectedRows.push(toSafeInteger(this.rowData[i].visit_id));
            }
          }
        }
      },
      get() {
        let totalItems = this.rowData.length;
        return totalItems > 0 ? this.selectedRows.length === totalItems : false;
      },
    },
  },
  beforeDestroy() {
    /*PaginationEventBus.$off("update:pagination");
    PaginationEventBus.$off("update:per-page");*/
  },
  created() {
    const _this = this;

    const statusText = _this.lodash.toLower(_this.$route.query.status) || "all";

    if (statusText) {
      switch (statusText) {
        case "all":
          _this.filter_status = 0;
          break;
        case "open":
          _this.filter_status = 1;
          break;
        case "cancel":
          _this.filter_status = 5;
          break;
      }
    }

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.getVisits();
    });

    PaginationEventBus.$on("update:per-page", (param) => {
      _this.perPage = param;
    });
  },
};
</script>
