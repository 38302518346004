<template>
  <Dialog :commonDialog="personDialog" :dialogWidth="dialogWidth">
    <template v-slot:title>
      <span v-if="doUpdate">Update</span>
      <span v-else>Add</span>
      <span v-if="isTenant">&nbsp;Tenant</span
      ><span v-else>&nbsp;Contact Person</span>
    </template>
    <template v-slot:body>
      <v-form
        ref="personForm"
        v-model.trim="formValid"
        lazy-validation
        v-on:submit.stop.prevent="appendContactPerson"
      >
        <v-row>
          <v-col class="iv-custom-field" cols="9" md="9" sm="9">
            <v-row v-if="personDialog" class="mx-3">
              <v-col md="4">
                <label
                  class="font-size-16 font-weight-500 required"
                  for="y-salutation"
                  >Salutation</label
                >
                <v-select
                  id="y-salutation"
                  v-model.trim="contactPerson.title"
                  :items="salutationList"
                  dense
                  filled
                  :rules="[
                    validateRules.required(contactPerson.title, 'salutation'),
                  ]"
                  item-text="text"
                  item-value="value"
                  item-color="cyan"
                  placeholder="Salutation"
                  solo
                  flat
                  color="cyan"
                ></v-select>
              </v-col>
              <v-col md="4">
                <label
                  class="font-size-16 font-weight-500 required"
                  for="y-first_name"
                  >First Name</label
                >
                <v-text-field
                  id="y-first_name"
                  v-model.trim="contactPerson.first_name"
                  :rules="[
                    validateRules.required(
                      contactPerson.first_name,
                      'first name'
                    ),
                    validateRules.minLength(
                      contactPerson.first_name,
                      'first name',
                      2
                    ),
                    validateRules.maxLength(
                      contactPerson.first_name,
                      'first name',
                      100
                    ),
                  ]"
                  dense
                  filled
                  placeholder="First Name"
                  solo
                  flat
                  color="cyan"
                  class="required-field"
                  v-on:keyup="appendDisplayName()"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <label class="font-size-16 font-weight-500" for="y-last_name"
                  >Last Name</label
                >
                <v-text-field
                  id="y-last_name"
                  v-model.trim="contactPerson.last_name"
                  :rules="[
                    validateRules.minLength(
                      contactPerson.last_name,
                      'last name',
                      2
                    ),
                    validateRules.maxLength(
                      contactPerson.last_name,
                      'last name',
                      100
                    ),
                  ]"
                  dense
                  filled
                  placeholder="Last Name"
                  solo
                  flat
                  color="cyan"
                  v-on:keyup="appendDisplayName()"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <label
                  class="font-size-16 font-weight-500 required"
                  for="y-display_name"
                  >Display Name (Attn. On PDF)</label
                >
                <v-text-field
                  id="y-display_name"
                  v-model.trim="contactPerson.display_name"
                  :rules="[
                    validateRules.required(
                      contactPerson.display_name,
                      'display name'
                    ),
                    validateRules.minLength(
                      contactPerson.display_name,
                      'display name',
                      2
                    ),
                    validateRules.maxLength(
                      contactPerson.display_name,
                      'display name',
                      100
                    ),
                  ]"
                  dense
                  filled
                  placeholder="Display Name"
                  solo
                  flat
                  color="cyan"
                  class="required-field"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <label
                  class="font-size-16 font-weight-500"
                  for="y-primary_phone"
                  >Mobile Number</label
                >
                <PhoneTextField
                  id="y-primary_phone"
                  v-model="contactPerson.primary_phone"
                  :phoneKey="1"
                  label="Mobile Number"
                >
                </PhoneTextField>
              </v-col>
              <v-col md="4">
                <label class="font-size-16 font-weight-500" for="y-did">
                  <template
                    v-if="
                      (customerType && customerType == 'company') ||
                      (customer && customer.company_type == 'company') ||
                      (addressCustomer &&
                        addressCustomer.company_type == 'company')
                    "
                    >DID</template
                  >
                  <template
                    v-else-if="
                      (customerType && customerType == 'personal') ||
                      (customer && customer.company_type == 'personal') ||
                      (addressCustomer &&
                        addressCustomer.company_type == 'personal')
                    "
                    >Landline Number</template
                  >
                  <template v-else>Landline Number</template>
                </label>
                <PhoneTextField
                  id="y-did"
                  v-model="contactPerson.did"
                  :phoneKey="2"
                  :label="label(customerType)"
                >
                </PhoneTextField>
              </v-col>
              <v-col md="4">
                <label
                  class="font-size-16 font-weight-500"
                  for="y-primary_email"
                  >Email Address</label
                >
                <!-- validateRules.required(contactPerson.primary_email, 'email'),-->
                <v-text-field
                  id="y-primary_email"
                  v-model.trim="contactPerson.primary_email"
                  :rules="[
                    validateRules.validEmail(
                      contactPerson.primary_email,
                      'email'
                    ),
                    validateRules.minLength(
                      contactPerson.primary_email,
                      'email',
                      2
                    ),
                    validateRules.maxLength(
                      contactPerson.primary_email,
                      'email',
                      100
                    ),
                  ]"
                  dense
                  filled
                  placeholder="Email"
                  solo
                  flat
                  color="cyan"
                ></v-text-field>
                <v-combobox
                  v-if="false"
                  small-chips
                  id="y-primary_email"
                  :items="contactPerson.emails"
                  v-model="contactPerson.emails"
                  placeholder="Emails"
                  color="cyan"
                  multiple
                  dense
                  filled
                  solo
                  flat
                  item-color="cyan"
                  v-on:change="validateTagEmail()"
                  hide-details
                  :rules="[
                    validateRules.required(contactPerson.emails, 'emails'),
                  ]"
                >
                  <template v-slot:selection="{ attrs, item, index, selected }">
                    <v-chip
                      label
                      small
                      v-bind="attrs"
                      :input-value="selected"
                      close
                      @click:close="removeEmail(item)"
                      v-if="index === 0"
                    >
                      <span>{{ item }}</span>
                    </v-chip>
                    <span
                      v-if="index === 1"
                      class="grey--text font-size-12 mx-2"
                    >
                      (+{{ contactPerson.emails.length - 1 }} others)
                    </span>
                  </template>
                </v-combobox>
              </v-col>
              <v-col md="4">
                <label class="font-size-16 font-weight-500" for="sap_cust"
                  >Customer Ref #</label
                >
                <v-text-field
                  id="sap_cust"
                  v-model.trim="contactPerson.sap_customer_no"
                  :rules="[
                    validateRules.minLength(
                      contactPerson.sap_customer_no,
                      'customer no',
                      3
                    ),
                    validateRules.maxLength(
                      contactPerson.sap_customer_no,
                      'customer no',
                      50
                    ),
                  ]"
                  dense
                  filled
                  placeholder="Customer Ref #"
                  solo
                  flat
                  color="cyan"
                  class="required-field"
                ></v-text-field>
              </v-col>
              <v-col md="4">
                <label class="font-size-16 font-weight-500" for="uen"
                  >Ref No.</label
                >
                <v-text-field
                  id="cust_vend_ref_no"
                  v-model.trim="contactPerson.cust_vend_ref_no"
                  :rules="[
                    validateRules.minLength(
                      contactPerson.cust_vend_ref_no,
                      'ref no.',
                      3
                    ),
                    validateRules.maxLength(
                      contactPerson.cust_vend_ref_no,
                      'ref no.',
                      50
                    ),
                  ]"
                  dense
                  filled
                  placeholder="Ref No."
                  solo
                  flat
                  color="cyan"
                  class="required-field"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-if="personDialog" class="mx-3">
              <v-col md="6">
                <label class="font-size-16 font-weight-500" for="y-category"
                  >Category</label
                >
                <v-autocomplete
                  hide-details
                  v-model.trim="contactPerson.category"
                  :items="options.all_cms_categories"
                  dense
                  flat
                  filled
                  placeholder="Category"
                  item-color="cyan"
                  color="cyan"
                  solo
                  item-value="value"
                  item-text="text"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title
                        v-html="'No Category(s) Found.'"
                      ></v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <v-list-item-action class="ma-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content class="py-0">
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                      >
                        <template v-if="item.value == 'all'"
                          >{{ item.text }}
                        </template>
                        <template v-else>{{ item.text }} </template>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-action class="mr-0">
                      <v-chip
                        style="height: 16px; width: 16px; padding: 0"
                        :color="item.color"
                      >
                      </v-chip>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title
                        class="text-capitalize font-weight-500 font-size-16"
                        >{{ item.text }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col md="6">
                <label class="font-size-16 font-weight-500" for="tags"
                  >Tags</label
                >
                <TagAutoComplete type="customer" v-model="contactPerson.tags" />
              </v-col>
            </v-row>
          </v-col>

          <v-col class="iv-custom-field" cols="3" md="3" sm="3">
            <template>
              <v-img
                :lazy-src="$defaultProfileImage"
                :src="customer?.profile_logo?.file?.url"
                aspect-ratio="1"
                max-height="200"
                max-width="200"
                class="margin-auto custom-grey-border custom-border-radius-50"
                transition="fade-transition"
              >
              </v-img>
              <div class="margin-auto">
                <v-btn
                  class="my-4 custom-bold-button width-100"
                  outlined
                  small
                  v-on:click="selectImage"
                  color="cyan"
                  :loading="imageLoading"
                  :disabled="imageLoading"
                >
                  <template>Change Image</template>
                </v-btn>
                <div class="d-none">
                  <v-file-input
                    ref="uploadImage"
                    v-on:change="updateProfileImage"
                    :rules="imageRules"
                    accept="image/png, image/jpeg, image/jpg"
                  ></v-file-input>
                </div>
              </div>
            </template>
          </v-col>
        </v-row>
      </v-form>
    </template>
    <template v-slot:action>
      <v-btn
        depressed
        v-on:click="$emit('close', true)"
        class="mx-2 custom-grey-border custom-bold-button"
      >
        Cancel
      </v-btn>
      <v-btn
        depressed
        class="mx-2 custom-bold-button white--text"
        v-on:click="appendContactPerson"
        color="cyan"
        :disabled="!formValid"
      >
        <span v-if="doUpdate">Update</span>

        <span v-else>Add</span>
      </v-btn>
    </template>
  </Dialog>
</template>
<script>
import { v4 as uuidv4 } from "uuid";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Dialog from "@/view/pages/partials/Dialog";
import PhoneTextField from "@/view/pages/partials/PhoneTextField";
import { SET_CC_PERSONS } from "@/core/services/store/common.module";
import { PUT } from "@/core/services/store/request.module";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import TagAutoComplete from "@/view/pages/partials/TagAutoCompleteInput";

//import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";
export default {
  model: {
    prop: "value",
    event: "input",
  },
  mixins: [ValidationMixin, CommonMixin, FileManagerMixin],
  data() {
    return {
      imageLoading: false,
      contactPersons: [],
      emails: [],
      contactPerson: {
        id: null,
        title: "mr",
        first_name: null,
        last_name: null,
        display_name: null,
        primary_email: null,
        emails: [],
        primary_phone: null,
        did: null,
        will_notified: 1,
        position: null,
        default: 0,
        tags: [],
        category: null,
      },
    };
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    personDialog: {
      type: Boolean,
      default: false,
    },
    isTenant: {
      type: Boolean,
      default: false,
    },
    doSave: {
      type: Boolean,
      default: false,
    },
    doUpdate: {
      type: Boolean,
      default: false,
    },
    customerId: {
      type: Number,
      default: 0,
    },
    addressCustomer: {
      type: Object,
      default: null,
    },
    customerType: {
      type: String,
      default: null,
    },
    customer: {
      type: Object,
      default: null,
    },
    personId: {
      type: Number,
      default: 0,
    },

    personUuid: {
      type: String,
      default: null,
    },
    personAlldata: {
      type: Object,
      default: null,
    },
    disableOne: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler() {
        this.initComponent();
      },
    },
    personDialog() {
      this.initComponent();
    },
    detail: {
      deep: true,
      handler() {
        this.makeDefaultEntry();
      },
    },
    personAlldata(param) {
      if (param) {
        this.contactPerson = param;
        //  console.log("conatct",this.contactPerson)
      } else {
        this.contactPerson = {};
      }
    },
  },
  methods: {
    selectImage() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    updateProfileImage(param) {
      const _this = this;
      let customerId =
        _this.customer && _this.customer.id ? _this.customer.id : 0;
      _this.imageLoading = true;
      _this
        .uploadAttachment(param, customerId, "customer", "logo")
        .then((response) => {
          _this.customer.profile_logo = _this.lodash.head(response);
          //console.log(response);
          console.log(_this.customer.profile_logo.file.url);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.imageLoading = false;
        });
    },
    getProfileImage() {
      //console.log(this.customer);
      if (!this.customer) {
        return null;
      }
      if (!this.customer.profile_logo) {
        return null;
      }

      return this.customer.profile_logo.file.url;
    },
    label() {
      if (
        (this.customerType && this.customerType == "company") ||
        (this.customer && this.customer.company_type == "company") ||
        (this.addressCustomer && this.addressCustomer.company_type == "company")
      ) {
        return "DID";
      } else if (
        (this.customerType && this.customerType == "personal") ||
        (this.customer && this.customer.company_type == "personal") ||
        (this.addressCustomer &&
          this.addressCustomer.company_type == "personal")
      ) {
        return "Landline Number";
      } else {
        return "Landline Number";
      }
    },
    validateTagEmail() {
      for (let i = 0; i < this.contactPerson.emails.length; i++) {
        if (
          this.validateRules.validEmail(this.contactPerson.emails[i]) !== true
        ) {
          this.contactPerson.emails.splice(i, 1);
        }
      }
    },
    removeEmail(email) {
      const index = this.lodash.findIndex(
        this.contactPerson.emails,
        (row) => email == row
      );
      if (index >= 0) {
        this.contactPerson.emails.splice(index, 1);
      }
    },
    initComponent() {
      const result = this.lodash.cloneDeep(this.value);
      this.contactPersons = result;

      if (this.doUpdate) {
        this.$nextTick(() => {
          let contact_person = this.lodash.find(this.contactPersons, {
            id: this.personId,
          });

          if (!contact_person) {
            contact_person = this.lodash.find(this.contactPersons, {
              uuid: this.personUuid,
            });
          }

          if (contact_person) {
            this.$nextTick(() => {
              this.contactPerson = {
                tags: this.customer?.tags,
                category: this.customer?.category,
                sap_customer_no: this.customer?.sap_customer_no,
                cust_vend_ref_no: this.customer?.cust_vend_ref_no,
                id: contact_person.id,
                uuid: contact_person.uuid,
                title: contact_person.title,
                first_name: contact_person.first_name,
                last_name: contact_person.last_name,
                display_name: contact_person.display_name,
                primary_email: contact_person.primary_email,
                emails: contact_person.emails,
                primary_phone: contact_person.primary_phone,
                did: contact_person.did,
                will_notified: +contact_person.will_notified,
                position: contact_person.position,
                default: +contact_person.default,
              };
            });
          }
        });
      } else {
        this.$nextTick(() => {
          let contactIndex = this.contactPersons.length + 1;
          this.contactPerson = {
            id: null,
            uuid: uuidv4(),
            title: "mr",
            first_name: null,
            last_name: null,
            display_name: null,
            primary_email: null,
            emails: [],
            primary_phone: null,
            did: null,
            will_notified: 1,
            position: null,
            default: 0,
            contactIndex: contactIndex,
          };
          this.$nextTick(() => {
            this.contactPerson.primary_phone = null;
          });
        });
      }
    },
    appendDisplayName() {
      /* if (!this.doUpdate) { */
      const result = [];
      const { first_name, last_name } = this.contactPerson;
      if (first_name) {
        result.push(first_name);
      }
      if (last_name) {
        result.push(last_name);
      }
      this.contactPerson.display_name = result.join(" ");
      /*  } */
    },

    async appendContactPerson() {
      if (!this.$refs.personForm.validate()) {
        return false;
      }

      let contactId = this.contactPerson.id;

      let temp_persons = this.lodash.cloneDeep(this.contactPersons);

      if (!this.disableOne) {
        if (!contactId) {
          if (this.personUuid) {
            let existIndex = this.lodash.findIndex(temp_persons, {
              uuid: this.personUuid,
            });
            if (existIndex >= 0) {
              temp_persons.splice(existIndex, 1, {
                menu: false,
                uuid: this.contactPerson.uuid,
                id: this.contactPerson.id,
                title: this.contactPerson.title,
                first_name: this.contactPerson.first_name,
                last_name: this.contactPerson.last_name,
                display_name: this.contactPerson.display_name,
                primary_email: this.contactPerson.primary_email,
                primary_phone: this.contactPerson.primary_phone,
                did: this.contactPerson.did,
                emails: this.contactPerson.emails,
                will_notified: this.contactPerson.will_notified,
                position: this.contactPerson.position,
                default: this.contactPerson.default,
                contactIndex: this.contactPerson.contactIndex,
              });
            }
          } else {
            temp_persons.push({
              menu: false,
              id: this.contactPerson.id,
              uuid: this.contactPerson.uuid,
              title: this.contactPerson.title,
              first_name: this.contactPerson.first_name,
              last_name: this.contactPerson.last_name,
              display_name: this.contactPerson.display_name,
              primary_email: this.contactPerson.primary_email,
              primary_phone: this.contactPerson.primary_phone,
              did: this.contactPerson.did,
              emails: this.contactPerson.emails,
              will_notified: this.contactPerson.will_notified,
              position: this.contactPerson.position,
              default: this.contactPerson.default,
              contactIndex: this.contactPerson.contactIndex,
            });
          }

          let alluniquemail = this.lodash.groupBy(
            temp_persons,
            "primary_email"
          );

          alluniquemail = this.lodash.map(
            alluniquemail,
            function (items, name) {
              return { name: name, count: items.length };
            }
          );
          alluniquemail = this.lodash.find(alluniquemail, function (row) {
            return row.count > 1;
          });

          if (alluniquemail && alluniquemail.count > 1) {
            /* ErrorEventBus.$emit(
              "update:error",
              InitializeError(`Email ${alluniquemail.name} already is used`)
            );
            return false; */
          }

          this.$emit("input", temp_persons);
          this.$emit("update:person", this.contactPerson);
          this.$emit("close", true);
          return false;
        }
      }

      if (!this.contactPerson.contactIndex) {
        this.contactPersons.push({
          menu: false,
          id: this.contactPerson.id,
          uuid: this.contactPerson.uuid,
          title: this.contactPerson.title,
          first_name: this.contactPerson.first_name,
          last_name: this.contactPerson.last_name,
          display_name: this.contactPerson.display_name,
          primary_email: this.contactPerson.primary_email,
          primary_phone: this.contactPerson.primary_phone,
          did: this.contactPerson.did,
          emails: this.contactPerson.emails,
          will_notified: this.contactPerson.will_notified,
          position: this.contactPerson.position,
          default: this.contactPerson.default,
          contactIndex: this.contactPerson.contactIndex,
        });
      } else {
        this.contactPersons[this.contactPerson.contactIndex - 1] = {
          menu: false,
          id: this.contactPerson.id,
          uuid: this.contactPerson.uuid,
          title: this.contactPerson.title,
          first_name: this.contactPerson.first_name,
          last_name: this.contactPerson.last_name,
          display_name: this.contactPerson.display_name,
          primary_email: this.contactPerson.primary_email,
          primary_phone: this.contactPerson.primary_phone,
          did: this.contactPerson.did,
          emails: this.contactPerson.emails,
          will_notified: this.contactPerson.will_notified,
          position: this.contactPerson.position,
          default: this.contactPerson.default,
          contactIndex: this.contactPerson.contactIndex,
        };
      }

      if (this.contactPerson.contactIndex) {
        this.doUpdate = false;
      }

      if (this.doSave || this.doUpdate) {
        this.contactPerson.tenant = this.lodash.toSafeInteger(this.isTenant);
        try {
          const uuid = uuidv4();

          this.contactPerson.uuid = uuid;

          const { data } = await this.$store.dispatch(PUT, {
            url: `customer/${this.customerId}/contact-person`,
            data: { persons: [this.contactPerson] },
          });

          this.contactPerson = this.lodash.find(data, { uuid });

          this.contactPersons = data;
          this.$emit("input", this.contactPersons);

          this.$emit("update:person", this.contactPerson);
        } catch (error) {
          this.logError(error);
          return false;
        }
      }

      if (this.isTenant) {
        const tenant = this.lodash.cloneDeep(this.contactPerson);

        this.$emit("update:tenant", tenant);
      }

      this.$store.dispatch(SET_CC_PERSONS, this.contactPersons);

      this.$nextTick(() => {
        this.contactPerson = {
          id: null,
          uuid: uuidv4(),
          title: "mr",
          first_name: null,
          last_name: null,
          display_name: null,
          primary_email: null,
          primary_phone: null,
          did: null,
          emails: [],
          will_notified: 1,
          position: null,
          default: 0,
        };
      });

      this.$emit("close", true);
    },
  },
  components: {
    Dialog,
    PhoneTextField,
    TagAutoComplete,
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 60);
    },
  },
  mounted() {
    this.initComponent();
  },
};
</script>
