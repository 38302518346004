// src/mixins/dateMixin.js
export default {
  methods: {
    formatDate(date) {
      console.log(date);
      const newDate = new Date(date);
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      return `${year}-${month < 10 ? "0" : ""}${month}-${
        day < 10 ? "0" : ""
      }${day}`;
    },
    getThisWeekDates() {
      const today = new Date();
      const dayOfWeek = today.getDay();
      const startDate = new Date(today);
      startDate.setDate(today.getDate() - dayOfWeek);
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      return {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
      };
    },
    getLastWeekDates() {
      const today = new Date();
      const lastSunday = new Date(today);
      lastSunday.setDate(today.getDate() - today.getDay() - 7);
      const lastSaturday = new Date(today);
      lastSaturday.setDate(today.getDate() - today.getDay() - 1);
      return {
        startDate: this.formatDate(lastSunday),
        endDate: this.formatDate(lastSaturday),
      };
    },
    getLast7DaysDates() {
      const endDate = new Date();
      const startDate = new Date(endDate);
      startDate.setDate(endDate.getDate() - 6);
      return {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
      };
    },
    getThisMonthDates() {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), today.getMonth(), 1);
      const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0);
      return {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
      };
    },
    getLastMonthDates() {
      const today = new Date();
      const lastMonthStartDate = new Date(
        today.getFullYear(),
        today.getMonth() - 1,
        1
      );
      const lastMonthEndDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      return {
        startDate: this.formatDate(lastMonthStartDate),
        endDate: this.formatDate(lastMonthEndDate),
      };
    },
    getLast3MonthDates() {
      const today = new Date();
      const lastMonthStartDate = new Date(
        today.getFullYear(),
        today.getMonth() - 3,
        1
      );
      const lastMonthEndDate = new Date(
        today.getFullYear(),
        today.getMonth(),
        0
      );
      return {
        startDate: this.formatDate(lastMonthStartDate),
        endDate: this.formatDate(lastMonthEndDate),
      };
    },
    getThisYearDates() {
      const today = new Date();
      const startDate = new Date(today.getFullYear(), 0, 1);
      const endDate = new Date(today.getFullYear(), 11, 31);
      return {
        startDate: this.formatDate(startDate),
        endDate: this.formatDate(endDate),
      };
    },
    getLastYearDates() {
      const today = new Date();
      const lastYearStartDate = new Date(today.getFullYear() - 1, 0, 1);
      const lastYearEndDate = new Date(today.getFullYear() - 1, 11, 31);
      return {
        startDate: this.formatDate(lastYearStartDate),
        endDate: this.formatDate(lastYearEndDate),
      };
    },
    getToday() {
      const today = new Date();
      return {
        startDate: today.toISOString().split("T")[0],
        endDate: today.toISOString().split("T")[0],
      };
    },
  },
};
