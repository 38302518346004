<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid>
    <v-row>
      <v-col md="3" v-if="false">
        <v-autocomplete
          v-model.trim="filter_status"
          :items="statusList"
          dense
          flat
          filled
          label="Visit Status"
          item-color="cyan"
          color="cyan"
          solo
          :disabled="pageLoading"
          :loading="pageLoading"
          item-value="status"
          item-text="text"
          class="filter-select"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Status(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:selection="{ item }">
            <v-list-item-action class="ma-0">
              <v-chip
                style="height: 10px; width: 10px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content class="py-0">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
              >
                <template v-if="item.value == 'all'"
                  >{{ item.text }} ({{ item.all_ticket_visits }})</template
                >
                <template v-else
                  >{{ item.text }} ({{
                    item.ticket_visit_status_count
                  }})</template
                >
              </v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action class="mr-0">
              <v-chip
                style="height: 16px; width: 16px; padding: 0"
                :color="item.color"
              >
              </v-chip>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.text }}</v-list-item-title
              >
            </v-list-item-content>
            <v-list-item-action
              class="align-self-center common-listing-row:nth-child"
            >
              <v-list-item-subtitle
                class="text-lowercase font-weight-500 font-size-14"
              >
                <template v-if="item.value == 'all'">{{
                  item.all_ticket_visits
                }}</template>
                <template v-else>{{
                  item.ticket_visit_status_count
                }}</template></v-list-item-subtitle
              >
            </v-list-item-action>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="3" v-if="type != 'property' && type != 'ticket'">
        <v-autocomplete
          class="ml-1"
          hide-details
          clearable
          v-model.trim="filter.property"
          :items="all_property"
          dense
          flat
          filled
          placeholder="Site Location"
          v-on:click:clear="clearFilter('property')"
          item-color="cyan"
          color="cyan"
          solo
          item-text="barcode"
          item-value="id"
          v-on:change="get_all_visits()"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title
                v-html="'No Site Location(s) Found.'"
              ></v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-icon class="mr-3">
              <v-icon>mdi-home-map-marker</v-icon>
            </v-list-item-icon>
            <v-list-item-content class="text-ellipsis max-width-400px">
              <v-list-item-title
                class="text-capitalize font-weight-500 font-size-16"
                >{{ item.barcode }} (
                <template v-if="item.unit_no"> {{ item.unit_no }},</template>
                <template v-if="item.street_1"> {{ item.street_1 }},</template>
                <template v-if="item.street_2"> {{ item.street_2 }},</template>
                <template v-if="item.zip_code">{{ item.street_2 }}</template
                >)
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col md="3">
        <v-menu
          v-if="false"
          v-model="datePicker"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              dense
              filled
              flat
              placeholder="Dates Range"
              hide-details
              solo
              :disabled="dataLoading"
              :loading="dataLoading"
              v-on:click:clear="clearFilter('dates')"
              clearable
              prepend-inner-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              :value="formattedDate"
              class="mr-2"
              color="cyan"
            ></v-text-field>
          </template>
          <v-date-picker
            color="cyan"
            range
            :disabled="dataLoading"
            :loading="dataLoading"
            v-on:change="get_all_visits"
            v-model="dates"
          ></v-date-picker>
        </v-menu>
        <DateRangePicker
          placeholder="Data Range"
          v-model="dates"
          v-on:update:model-value="get_all_visits()"
          v-on:click:clear="clearFilter('dates')"
        ></DateRangePicker>
      </v-col>
      <v-col md="3" class="pl-0 d-flex">
        <v-text-field
          v-model.trim="filter.search"
          v-on:click:clear="clearFilter('search')"
          clearable
          dense
          filled
          flat
          placeholder="Search"
          hide-details
          solo
          :disabled="dataLoading"
          :loading="dataLoading"
          color="cyan"
          @keydown.enter="get_all_visits"
          @keydown.tab="get_all_visits"
        ></v-text-field>
        <v-btn
          depressed
          class="mx-2 custom-bold-button white--text"
          color="cyan"
          tile
          :disabled="dataLoading"
          :loading="dataLoading"
          v-on:click="get_all_visits"
          style="height: 31px !important"
        >
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <ListingTable
      :column-count="columnCount"
      :data-loading="dataLoading"
      :row-data="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th">Visit #</th>
            <th class="simple-table-th">Title</th>
            <th class="simple-table-th" v-if="type == 'engineer'">Customer</th>
            <th
              class="simple-table-th"
              v-if="type != 'property' && type != 'ticket'"
            >
              Site Location
            </th>
            <th class="simple-table-th">Appointment Date</th>
            <th class="simple-table-th" v-if="false">Rating</th>
            <th class="simple-table-th">Status</th>
            <th class="simple-table-th">Assign To</th>
            <!--   <th class="simple-table-th">Created Time</th> -->
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows?.length">
            <template v-for="(row, index) in dataRows">
              <v-hover :key="index" v-slot="{ hover }">
                <tr
                  :class="
                    type == 'ticket'
                      ? 'visit-internal-listing-row'
                      : 'alternate-listing-row'
                  "
                  :key="index"
                >
                  <td>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <Barcode
                        v-if="row.trip_barcode"
                        small
                        :barcode="row?.trip_barcode"
                        :id="row?.visit_id"
                        :route="'visit.detail'"
                      ></Barcode>
                    </p>
                  </td>
                  <td>
                    <div class="m-0 custom-nowrap-ellipsis d-flex">
                      <template v-if="row?.visit_title">
                        <div class="my-auto ml-1">
                          <read-more
                            class="custom-read-more font-size-16 pb-1"
                            more-str="read more"
                            :text="row?.visit_title || '<em>No Title</em>'"
                            link="#"
                            less-str="read less"
                            :max-chars="35"
                          >
                          </read-more>
                        </div>
                      </template>
                      <em class="text-muted" v-else> no title </em>
                    </div>
                  </td>
                  <td v-if="type == 'engineer'">
                    <div class="m-0 custom-nowrap-ellipsis">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Customer #: </b>
                        <template v-if="row && row.customer_company_barcode"
                          ><span class="font-weight-700">{{
                            row.customer_company_barcode
                          }}</span></template
                        >
                        <template v-else
                          ><em class="text--secondary"
                            >No Display Name</em
                          ></template
                        >
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Display Name: </b>
                        <template v-if="row && row.customer_company_name">{{
                          row.customer_company_name
                        }}</template>
                        <template v-else
                          ><em class="text--secondary"
                            >No Display Name</em
                          ></template
                        >
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Email: </b>
                        <template v-if="row && row.company_email">{{
                          row.company_email
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Email</em></template
                        >
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Phone: </b>
                        <template v-if="row && row.company_number">{{
                          row.company_number
                        }}</template>
                        <template v-else
                          ><em class="text--secondary">No Phone</em></template
                        >
                      </p>
                    </div>
                  </td>
                  <td v-if="type != 'property' && type != 'ticket'">
                    <template>
                      <div class="custom-flex-d" v-if="row.property_name">
                        <b>
                          <v-icon
                            style="font-size: 18px; margin-top: -3px"
                            color="#000"
                            class="cmt-2"
                          >
                            mdi-home-map-marker
                          </v-icon>
                        </b>
                        <span>{{ row.property_name }}<br /></span>
                      </div>
                      <div v-else>
                        <em class="text-muted">
                          <v-icon
                            style="font-size: 18px; margin-top: -3px"
                            color="#000"
                            class="cmt-2"
                          >
                            mdi-home-map-marker
                          </v-icon>
                          no Site Name</em
                        >
                      </div>
                    </template>

                    <template>
                      <div class="custom-flex-d">
                        <b>
                          <v-icon
                            style="font-size: 18px; margin-top: -3px"
                            color="#000"
                            class="cmt-2"
                          >
                            mdi-map-marker
                          </v-icon>
                        </b>
                        <span>
                          <template v-if="row?.property_unit_no"
                            >{{ row.property_unit_no }},</template
                          >
                          <template v-if="row?.property_street_1"
                            >{{ row.property_street_1 }},</template
                          >
                          {{ row.property_street_2 }}
                          <br />
                          {{ row.property_country }}
                          <template
                            v-if="
                              row?.property_zip_code &&
                              row.property_zip_code != '000000'
                            "
                          >
                            {{ row?.property_zip_code }}
                          </template>
                        </span>
                      </div>
                    </template>

                    <!-- <div class="m-0 custom-nowrap-ellipsis d-flex">
                      <template v-if="row?.property_address">
                        <div class="my-auto ml-1">
                          <read-more
                            class="custom-read-more font-size-16 pb-1"
                            more-str="read more"
                            :text="row?.property_address || '<em>No Title</em>'"
                            link="#"
                            less-str="read less"
                            :max-chars="35"
                          >
                          </read-more>
                        </div>
                      </template>
                      <em class="text-muted" v-else> no site location </em>
                    </div> -->
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Start : </b>
                        {{ formatDateTime(row?.visit_started_at) }}
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>End : </b>
                        {{ formatDateTime(row?.visit_finished_at) }}
                      </p>
                    </div>
                  </td>
                  <td v-if="false">
                    <div class="custom-nowrap-ellipsis">
                      <template v-if="row.visit_status == 4">
                        <template v-if="row?.rating_status == 3">
                          <v-rating
                            v-if="row.visit_rating_type == 'star'"
                            readonly
                            dense
                            v-model.trim="row.visit_rating_value"
                            background-color="orange lighten-3"
                            color="orange"
                          ></v-rating>
                          <v-rating
                            color=""
                            v-model.trim="row.visit_rating_value"
                            readonly
                            v-if="
                              row.visit_status == 4 &&
                              row.visit_rating_type == 'number'
                            "
                          >
                            <template v-slot:item="props">
                              <v-avatar
                                class="mx-1"
                                style="border: 2px solid orange"
                                :color="
                                  props.isFilled ? 'orange text-white' : ''
                                "
                                size="25"
                              >
                                <span class="text-h6">{{
                                  props.index + 1
                                }}</span>
                              </v-avatar>
                            </template>
                          </v-rating>
                        </template>
                        <template v-else>
                          <v-chip
                            label
                            :color="getReviewStatusColor(row?.rating_status)"
                            text-color="white"
                            small
                            class="ml-1"
                          >
                            {{ row?.rating_status_text }}
                          </v-chip>
                        </template>
                      </template>
                      <template v-else>
                        <em class="text-muted"> no rating</em>
                      </template>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <CustomStatus
                        small
                        :status="row?.visit_status"
                        endpoint="visit/status"
                      ></CustomStatus>
                    </div>
                  </td>
                  <td>
                    <div class="custom-nowrap-ellipsis">
                      <p
                        class="m-0 max-content-width text-truncate font-weight-700 text-capitalize"
                        :class="{
                          'text--secondary': lodash.isEmpty(
                            row.visit_engineers
                          ),
                        }"
                      >
                        {{ engineerDisplayText(row.visit_engineers) }}
                      </p>
                    </div>
                    <div>
                      <v-chip
                        v-if="
                          row?.visit_engineers &&
                          row.visit_engineers?.length > 0
                        "
                        label
                        color="orange"
                        class="p-3 mr-5"
                        text-color="white"
                        small
                        >Assign</v-chip
                      >
                      <v-chip
                        v-if="
                          row?.visit_engineers &&
                          row?.visit_engineers?.length == 0
                        "
                        label
                        color="#24326d"
                        class="p-3 mr-5"
                        text-color="white"
                        small
                        >Unassign</v-chip
                      >
                    </div>
                  </td>
                  <td class="simple-table-td" v-if="false">
                    <TableActivity :data="row">
                      <template v-slot:date_time>
                        <template v-if="getDateCountAll(row?.added_at)">{{
                          row?.created_at
                        }}</template>
                        <template v-else>{{
                          formatDateTime(row?.added_at)
                        }}</template>
                      </template>
                      <template v-slot:format_date_time>
                        {{ formatDate(row?.added_at) }}
                        {{ formatTime(row?.added_at) }}
                      </template>
                    </TableActivity>
                  </td>
                </tr>
              </v-hover>
            </template>
          </template>
          <tr v-else>
            <td :colspan="columnCount">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no visit at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import { QUERY } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
/*   import moment from "moment"; */
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import DateRangePicker from "@/view/components/DateRangePicker";

export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      warrantyDurationTypes: [
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 15,
      currentPage: 1,
      totalRows: 0,
      all_property: [],
      rowsOffset: 0,
      dataLoading: true,
      manage_brand: false,
      manage_location: false,
      form_loading: false,
      image_loading: false,
      filter: {
        status: "all",
        search: null,
        property: null,
        customer: null,
        engineer: null,
      },
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },

    customerId: {
      type: Number,
      default: 0,
    },
    propertyId: {
      type: Number,
      default: 0,
    },
    engineerId: {
      type: Number,
      default: 0,
    },
    invoiceId: {
      type: Number,
      default: 0,
    },
    quotationId: {
      type: Number,
      default: 0,
    },
    ticketId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.parent = this.lodash.toSafeInteger(param.id);
          if (this.parent > 0) {
            this.get_all_visits();
          }
        }
      },
    },
  },
  components: {
    CustomStatus,
    TableActivity,
    /*   Dialog, */

    ListingFooter,
    ListingTable,
    Barcode,
    DateRangePicker,
  },
  methods: {
    detailWarrnty(param) {
      this.$router.push(
        this.getDefaultRoute("warranty.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    detailvisit(param) {
      this.$router.push(
        this.getDefaultRoute("visit.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    getReviewStatusColor(rating_status) {
      switch (rating_status) {
        case 1:
          return "cyan";
        case 2:
          return "orange";
        case 3:
          return "green";
        case 4:
          return "red";
        case 5:
          return "orange";
      }
    },
    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },

    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
        case "property":
          _this.filter.property = null;
          break;
        case "customer":
          _this.filter.customer = null;
          break;
      }
      _this.get_all_visits();
    },
    engineerDisplayText(param) {
      const _this = this;
      let display_name = new Array();
      if (
        _this.lodash.isEmpty(param) === false &&
        _this.lodash.isArray(param)
      ) {
        display_name.push(param[0].display_name);
        if (param.length - 1) {
          const message = ` +${param.length - 1} more...`;
          display_name.push(message);
        }
      }
      if (_this.lodash.isEmpty(display_name)) {
        return "Not assigned.";
      } else {
        return display_name.join(", ");
      }
    },
    get_all_visits() {
      const _this = this;
      if (this.filter?.property > 0) {
        this.propertyId = this.filter?.property;
      }
      if (this.filter?.customer > 0) {
        this.customerId = this.filter?.customer;
      }
      if (this.filter?.engineer > 0) {
        this.engineerId = this.filter?.engineer;
      }
      let filter = {
        status: "all",
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        search: _this.filter.search || undefined,
        customer: this.customerId ? this.customerId : null,
        property: this.propertyId ? this.propertyId : null,
        engineer: this.engineerId ? this.engineerId : null,
        invoice: this.invoiceId ? this.invoiceId : null,
        quotation: this.quotationId ? this.quotationId : null,
        ticket: this.ticketId ? this.ticketId : null,
        dates: this.dates ? this.dates : [],
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: `internal/visits`,
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
    get_property() {
      this.$store
        .dispatch(QUERY, {
          url: `property/customer/${this.customerId}`,
          data: { search: this.search, status: "all" },
        })
        .then(({ data }) => {
          this.all_property = data.rows;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
  },
  mounted() {
    const _this = this;

    //_this.get_options();

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_visits();
    });
    _this.get_property();
    /*   _this.$nextTick(() => {
        _this.equipment.customer = _this.equipmentId;
      }); */
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows?.length : 0);
    },
  },
};
</script>
