<template>
  <v-container
    fluid
    v-if="
      getFields.length ||
      (getAttachment.length &&
        getAttachment[0].initialVal &&
        getAttachment[0].initialVal.length)
    "
  >
    <v-card flat class="custom-grey-border remove-border-radius m b-4 h-100">
      <v-card-title class="headline grey lighten-4 py-0">
        <span
          class="font-weight-700 custom-headline color-custom-blue font-size-16"
        >
          {{
            gModuleTitle[module] ? gModuleTitle[module] : "Other Information"
          }}
        </span>
        <v-btn
          icon
          height="30"
          width="30"
          color="cyan"
          class="rounded-circle"
          @click="updateDialog = true"
          v-if="getFields.length"
        >
          <v-icon small>mdi-pencil</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="p-6 font-size-16">
        <div class="mb-0" v-if="getFields.length">
          <table class="width-100">
            <template v-for="(data, index) in getFields">
              <tr :key="data.label + index">
                <td
                  class="py-1 font-size-18 text-capitalize font-weight-500"
                  width="150"
                >
                  {{ data.label }}
                </td>
                <td class="py-1">
                  <div v-if="data.initialVal" class="font-size-18">
                    <template v-if="data.field_type == 'checkbox'">
                      <template v-if="Array.isArray(data.initialVal)">
                        <span
                          v-for="(row, index) in data.initialVal"
                          :key="index"
                        >
                          {{ row }}
                        </span>
                      </template>
                    </template>
                    <template v-else-if="data.field_type == 'date-time'">
                      {{ formatDateTime(data.initialVal) }}
                    </template>
                    <template v-else-if="data.field_type == 'date'">
                      {{ formatDate(data.initialVal) }}
                    </template>
                    <template v-else>
                      {{ data.initialVal }}
                    </template>
                  </div>
                  <em v-else class="text--secondary text-lowercase">
                    no {{ data.label }}
                  </em>
                </td>
              </tr>
            </template>
          </table>
        </div>
        <div
          class="mb-5 px-2"
          v-if="
            getAttachment.length &&
            getAttachment[0].initialVal &&
            getAttachment[0].initialVal.length
          "
        >
          <div class="font-weight-500">Attachment</div>
          <v-col
            md="12"
            v-for="(row, index) in getAttachment[0].initialVal"
            :key="index"
            class="border-bottom d-flex"
            :class="{
              'custom-border-top': index == 0,
              'orange_lighten-5': index % 2 == 0,
            }"
          >
            <div class="px-4" style="width: 60px">
              <template v-if="row.fileType && isImage(row.fileType)">
                <ImageTemplate
                  :src="row.value"
                  style="max-width: 50px; width: 50px"
                ></ImageTemplate>
              </template>
              <template v-else>
                <inline-svg
                  style="max-width: 50px; width: 50px"
                  :src="$assetURL(`media/mime/${row.fileType}.svg`)"
                />
              </template>
            </div>
            <div style="width: 30%" class="px-4 text-truncate">
              <b
                class="blue--text text--darken-4 cursor-pointer"
                v-on:click="doAction(row, 'download')"
                >{{ row.fileName }}</b
              >
              <div v-if="row.fileSize">
                <b>Size :</b
                >{{ Number(Number(row.fileSize) / 1024).toFixed(2) }}KB
              </div>
            </div>
            <div style="width: 40%" class="px-4 d-flex flex-wrap">
              <div class="mr-10" v-if="row.start_date || row.end_date">
                <div>
                  <b class="d-inline-block" style="width: 40px">Start </b
                  >:&nbsp;&nbsp;
                  <span v-if="row.start_date">
                    {{ formatDate(row.start_date) }}
                  </span>
                  <em class="text-muted" v-else> no start date </em>
                </div>
                <div>
                  <b class="d-inline-block" style="width: 40px">End </b
                  >:&nbsp;&nbsp;
                  <span v-if="row.end_date">
                    {{ row.end_date }}
                  </span>
                  <em class="text-muted" v-else> no end date </em>
                </div>
              </div>
              <div class="mr-10" v-if="row.reminder_date">
                <b class="d-inline-block" style="width: 64px">Reminder </b
                >:&nbsp;&nbsp;
                <span v-if="row.reminder_date">
                  {{ row.reminder_date }}
                </span>
                <em class="text-muted" v-else> no reminder date </em>
              </div>
            </div>
            <div
              style="width: 20%"
              class="px-4 d-flex flex-wrap"
              v-if="row.added_at"
            >
              <div class="mr-0">
                <div>
                  <b class="d-inline-block" style="width: 80px">Created At</b
                  >:&nbsp;&nbsp;
                  <span v-if="row.added_at">
                    {{ formatDate(row.added_at) }}
                  </span>
                  <em class="text-muted" v-else> no created </em>
                </div>
                <div>
                  <b class="d-inline-block" style="width: 80px">Created by </b
                  >:&nbsp;&nbsp;
                  <span v-if="row.user_display_name">
                    {{ row.user_display_name }}
                  </span>
                  <em class="text-muted" v-else> no Created by </em>
                </div>
              </div>
            </div>
            <div style="width: 10%" class="px-4 d-flex">
              <v-spacer></v-spacer>
              <v-btn
                v-on:click="doAction(row, 'download')"
                small
                icon
                depressed
                color="blue darken-4"
                class="mx-1"
              >
                <v-icon medium>mdi-download</v-icon>
              </v-btn>
              <v-btn
                v-if="false"
                v-on:click="doAction(row, 'delete')"
                icon
                small
                depressed
                class="mx-1"
                color="red lighten-1"
                ><v-icon medium>mdi-delete</v-icon></v-btn
              >
            </div>
          </v-col>
        </div>
      </v-card-text>
    </v-card>
    <Dialog v-if="updateDialog" :dialog="updateDialog" :dialog-width="1200">
      <template v-slot:title>
        Update
        {{ gModuleTitle[module] ? gModuleTitle[module] : "Other Information" }}
      </template>
      <template v-slot:body>
        <v-form
          ref="custom-field-update-form"
          lazy-validation
          v-on:submit.stop.prevent="onUpdate"
        >
          <CustomForm
            :module="module"
            :module-id="moduleId"
            ref="custom-field-update-form-ref"
          />
        </v-form>
      </template>
      <template v-slot:action>
        <v-btn
          depressed
          tile
          :disabled="pageLoading"
          @click="updateDialog = false"
        >
          Cancel
        </v-btn>
        <v-btn
          class="white--text"
          depressed
          color="cyan"
          tile
          :loading="pageLoading"
          @click="onUpdate"
        >
          Update
        </v-btn>
      </template>
    </Dialog>
    <!-- <Dialog :dialog="deleteDialog" :dialog-width="600">
      <template v-slot:title> Delete File</template>
      <template v-slot:body>
        <v-row class="delete-dialog">
          <v-col md="2" class="py-0 text-right my-auto">
            <span class="svg-icon svg-icon-lg delete-confirm-icon">
              <inline-svg
                :src="$assetURL('media/custom-svg/attention-circle.svg')"
              />
            </span>
          </v-col>
          <v-col md="10" class="py-0 my-auto">
            <p class="btx-p m-0">
              Deleting File is irreversible, Are you sure about deleting it?
            </p>
          </v-col>
        </v-row>
      </template>
      <template v-slot:action>
        <v-btn
          class="white--text"
          :loading="deleteLoading"
          :disabled="deleteLoading"
          depressed
          color="red lighten-1"
          tile
          v-on:click="deleteFile()"
        >
          Yes! Delete
        </v-btn>
        <v-btn
          depressed
          tile
          :disabled="deleteLoading"
          v-on:click="deleteDialog = false"
        >
          No, Close
        </v-btn>
      </template>
    </Dialog> -->
    <DeleteTemplate
      type="File"
      :deleteUrl="deleteURL"
      :deleteText="deleteText"
      :deleteDialog="deleteDialog"
      v-on:close="deleteDialog = false"
      v-on:success="
        () => {
          deleteDialog = false;
        }
      "
    ></DeleteTemplate>
  </v-container>
</template>
<script>
import ValidationMixin from "@/core/plugins/validation-mixin";
import CommonMixin from "@/core/plugins/common-mixin";
import ApiService from "@/core/services/api.service";
import ImageTemplate from "@/view/pages/Image";
import Dialog from "@/view/components/Dialog.vue";
import DeleteTemplate from "@/view/components/app-component/DeleteTemplate.vue";
import CustomForm from "@/view/pages/custom-field-v2/CustomForm.vue";
import { PUT } from "@/core/services/store/request.module";
import { SET_ACTIVE_FIELD_DATA } from "@/core/services/store/custom.fields.module.js";
import { mapGetters } from "vuex";
import { filter, find } from "lodash";
import {
  ErrorEventBus,
  SuccessEventBus,
  InitializeError,
} from "@/core/lib/message.lib";
export default {
  name: "CustomFormDetail",
  mixins: [ValidationMixin, CommonMixin],

  data() {
    return {
      pageLoading: false,
      updateDialog: false,
      deleteDialog: false,
      deleteLoading: false,
      deleteURL: null,
      deleteText: null,
      deleteId: 0,
      extensionArray: ["jpg", "png", "gif", "jpeg", "webp", "tiff", "jfif"],
    };
  },
  props: {
    module: {
      type: String,
      default: null,
    },
    moduleId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    ...mapGetters([
      "gActiveFormFields",
      "gCreateFormFields",
      "gFormTitle",
      "gModuleTitle",
    ]),
    getFields() {
      return filter(
        this.gActiveFormFields,
        (row) => row.field_type != "attachment"
      );
    },
    getAttachment() {
      return filter(
        this.gActiveFormFields,
        (row) => row.field_type == "attachment"
      );
    },
  },
  methods: {
    onUpdate() {
      const _this = this;
      this.$refs["custom-field-update-form"].validate();
      const formErrors = this.validateForm(
        this.$refs["custom-field-update-form"]
      );
      if (formErrors.length) {
        for (let i = 0; i < formErrors.length; i++) {
          ErrorEventBus.$emit("update:error", InitializeError(formErrors[i]));
        }
        return false;
      }

      _this.pageLoading = true;
      _this.$store
        .dispatch(PUT, {
          url: "module/store-custom-fields-data",
          data: {
            parent_id: this.moduleId,
            parent_type: this.module,
            custom_fields: this.gCreateFormFields,
          },
        })
        .then(() => {
          _this.$store.commit(SET_ACTIVE_FIELD_DATA, this.gCreateFormFields);
          SuccessEventBus.$emit(
            "update:success",
            `${_this.module} updated successfully.`
          );
          _this.updateDialog = false;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    doAction(row, type) {
      switch (type) {
        case "download":
          window.open(row.value, "_blank");
          break;
        case "delete":
          this.deleteId = row.id;
          this.deleteText = row.fileName;
          this.deleteURL = "delete-documents/" + row.id;
          this.deleteDialog = true;
          break;
      }
    },
    deleteFile() {
      if (!this.deleteId) {
        return false;
      }
      this.deleteLoading = true;
      ApiService.setHeader();
      ApiService.delete(`delete-documents/${this.deleteId}`)
        .then(() => {
          // this.getFiles();
        })
        .catch((error) => {
          this.$emit("error", error);
          this.logError(error);
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    isImage(ext) {
      if (find(this.extensionArray, (row) => row == ext.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    CustomForm,
    Dialog,
    ImageTemplate,
    DeleteTemplate,
  },
};
</script>
