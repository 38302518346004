<template>
  <div class="postal-code-input">
    <v-text-field
      v-bind="{
        ...$attrs,
      }"
      :id="postalCodeId"
      append-icon="search"
      :append-inner-icon="pageloading ? 'mdi-spin mdi-loading' : ''"
      @keyup="(e) => searchAddress(e, e.target.value)"
      placeholder="Search Address"
      solo
      ref="searchinput"
      hide-spin-buttons
      flat
      color="cyan"
    >
    </v-text-field>

    <v-menu
      max-width="300"
      v-model="searchMenu"
      v-if="postalAddressList.length > 1"
      max-height="400"
      offset-y
      ref="searchmenu"
      left
      :activator="`#${postalCodeId}`"
    >
      <v-list>
        <v-list-item
          v-for="(item, index) in postalAddressList"
          :key="index"
          :value="index"
          class="border-b"
          @click="setAddress(item)"
        >
          <template v-slot:prepend>
            <v-icon size="24" class="me-3" :icon="'mdi-map'"></v-icon>
          </template>
          <v-list-item-title class="white-space-pre-wrap">{{
            item.description
          }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { QUERY } from "@/core/services/store/request.module";
import { lowerCase } from "lodash";
import ValidationMixin from "@/core/plugins/validation-mixin";

export default {
  mixins: [ValidationMixin],
  name: "pending-line-item",
  props: {
    country: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: "postal-menu-activator",
    },
    countryCode: {
      type: String,
      default: null,
    },
  },
  data: () => {
    return {
      pageloading: false,
      timeoutLimit: 500,
      timeout: null,
      searchMenu: false,
      postalAddressList: [],
      postalCodeId: "postal-id" + +new Date(),
    };
  },
  computed: {
    getCountryCode() {
      return this.countryCode;
    },
  },
  methods: {
    setAddress(address) {
      this.searchMenu = false;
      this.$emit("update:address", address);
    },
    searchAddress(event, number) {
      if (
        event &&
        (event.key == "ArrowUp" ||
          event.key == "ArrowDown" ||
          event.key == "ArrowLeft" ||
          event.key == "ArrowRight" ||
          event.key == "Enter")
      ) {
        return false;
      }
      if (number) {
        let _this = this;
        let url = "";
        let Request = {};
        clearTimeout(_this.timeout);
        _this.timeout = setTimeout(function () {
          if (_this.country == "singapore") {
            /*  if (number && number.length < 6 && this.country == "singapore") { */
            url = "https://micro.businessthrust.com/api/onemap-api/search";
            Request = {
              search: number,
            };
            /*  }else{
                return false;
              }  */
          } else {
            url = "https://micro.businessthrust.com/api/google-api/search";
            Request = {
              search: number,
              country: _this.getCountryCode ? _this.getCountryCode : "in",
            };
          }
          _this.$store
            .dispatch(QUERY, {
              url: url,
              data: Request,
            })
            .then(({ data }) => {
              if (data && data.length) {
                _this.searchMenu = true;
                if (lowerCase(_this.getCountryCode) != "sg") {
                  _this.postalAddressList = data.map((row) => {
                    const _address_2 = row.description;
                    return {
                      address1: _address_2,
                      address2: null,
                      unit_number: null,
                      postal_code: "000000",
                      description: row.description,
                    };
                  });
                } else {
                  _this.postalAddressList = data.map((row) => {
                    const _unit_number = null;
                    const _address_1 =
                      row.road_name != "NIL" ? row.road_name : null;
                    const _address_2 =
                      row.building != "NIL" ? row.building : null;
                    const _postal_code =
                      row.postal_code != "NIL" ? row.postal_code : "000000";
                    const longitude =
                      row.longitude != "NIL" ? row.longitude : null;
                    const latitude =
                      row.latitude != "NIL" ? row.latitude : null;

                    return {
                      address1: _address_1,
                      address2: _address_2,
                      unit_number: _unit_number,
                      longitude: longitude,
                      latitude: latitude,
                      postal_code: _postal_code,
                      description: row?.address,
                    };
                  });
                }
              } else {
                _this.searchMenu = false;
              }

              if (
                _this.postalAddressList &&
                _this.postalAddressList.length == 1
              ) {
                _this.setAddress(_this.postalAddressList[0]);
              }
              /*_this.$nextTick(() => {
                _this.$refs["searchinput"]?.$refs['input']?.blur()
              })*/
            })
            .catch((error) => {
              _this.logError(error);
            });
        }, _this.timeoutLimit);
      }
    },
  },
};
</script>
<style scoped></style>
