<template>
  <div>
    <DetailTemplate
      :customClass="'customer-detail detail-page'"
      v-if="getPermission('customer:view')"
    >
      <template v-slot:sidebar-detail>
        <v-card class="h-100" elevation="0">
          <div class="d-flex align-center">
            <v-chip
              :color="getColor(warrantyArr.status)"
              label
              v-if="warrantyArr.status"
              class="mr-2"
            >
              <span class="font-size-16 font-weight-500">
                {{ getStatus(warrantyArr.status) }}
              </span>
            </v-chip>
            <v-tooltip
              right
              content-class=""
              v-if="warrantyArr?.extension_count > 0"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-avatar color="red" size="30" v-bind="attrs" v-on="on">
                  <span
                    class="white--text font-weight-700"
                    v-bind="attrs"
                    v-on="on"
                  >
                    E {{ warrantyArr.extension_count }}</span
                  >
                </v-avatar>
              </template>
              <span> Extension </span>
            </v-tooltip>
            <!--  <v-chip
              v-if="
                warrantyArr &&
                warrantyArr.equipment &&
                warrantyArr.equipment.main_type == 'main_company'
              "
              label
              color="#0d47a1"
              outlined
              class="p-3 ml-2"
            >
              Own Asset
            </v-chip> -->
            <!--  <v-chip
              small
              label
              :color="
                get_equp_type_color(warrantyArr?.equipment?.equipemt_type)
              "
              text-color=""
              outlined
            >
              {{ get_equp_type(warrantyArr?.equipment?.equipemt_type) }}
            </v-chip> -->
          </div>
          <div class="px-2 py-5 customer-image text-center">
            <v-skeleton-loader
              class="custom-skeleton"
              v-if="pageLoading"
              type="image"
            >
            </v-skeleton-loader>
            <template v-else>
              <v-img
                :lazy-src="$defaultImage"
                :src="
                  warrantyArr &&
                  warrantyArr.equipment &&
                  warrantyArr.equipment.image
                    ? warrantyArr.equipment.image
                    : $defaultImage
                "
                aspect-ratio="1"
                max-height="140"
                max-width="140"
                class="margin-auto custom-grey-border custom-border-radius-50"
                transition="fade-transition"
              >
              </v-img>
            </template>
          </div>
          <div class="">
            <span
              class="gray-background py-1 px-2 d-block color-custom-blue font-weight-700 font-size-16"
            >
              Warranty Detail
            </span>
            <table width="100%">
              <tr>
                <td
                  valign="middle"
                  class="py-2 font-size-18 font-weight-500"
                  width="100"
                >
                  Warranty #
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <v-chip
                    small
                    label
                    color="chip-custom-blue"
                    outlined
                    class="text-white p-3 mr-2"
                    text-color=""
                    v-if="warrantyArr && warrantyArr.unique_id"
                  >
                    {{ warrantyArr.unique_id }}
                  </v-chip>
                  <em v-else class="text--secondary"> no Warranty #</em>
                  <showBarcode
                    :barcodeUrl="
                      warrantyArr &&
                      warrantyArr.barcode_image &&
                      warrantyArr.barcode_image
                    "
                    title="Warranty Serial No"
                  ></showBarcode>
                </td>
              </tr>
              <tr>
                <td
                  valign="middle"
                  class="py-2 font-size-18 font-weight-500"
                  width="100"
                >
                  Reference No
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <span v-if="warrantyArr && warrantyArr.reference">
                    {{ warrantyArr.reference }}
                  </span>
                  <em v-else class="text--secondary"> no Reference No</em>
                </td>
              </tr>
              <tr>
                <td valign="middle" class="py-2 font-size-18 font-weight-500">
                  Start Date
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <label
                    class="m-0"
                    v-if="warrantyArr && warrantyArr.start_date"
                  >
                    {{ formatDate(warrantyArr.start_date) }}
                  </label>
                  <em v-else class="text--secondary"> no start date </em>
                </td>
              </tr>
              <tr>
                <td valign="middle" class="py-2 font-size-18 font-weight-500">
                  End Date
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <label class="m-0" v-if="warrantyArr && warrantyArr.end_date">
                    {{ formatDate(warrantyArr.end_date) }}
                  </label>
                  <em v-else class="text--secondary"> no end date </em>
                </td>
              </tr>
              <tr v-if="warrantyArr && warrantyArr.end_date">
                <td valign="middle" class="py-2 font-size-18 font-weight-500">
                  Expiring on
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <label class="m-0" v-if="warrantyArr && warrantyArr.end_date">
                    {{ formatDate(warrantyArr.end_date) }}
                    <b class="font-weight-700"> midnight.</b>
                  </label>
                </td>
              </tr>
              <tr v-if="warrantyArr && warrantyArr.end_date">
                <td valign="middle" class="py-2 font-size-18 font-weight-500">
                  Remaining
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <label class="m-0" v-if="warrantyArr && warrantyArr.end_date">
                    {{ remaningDate(warrantyArr) }}
                    <b>
                      <span style="color: red; font-weight: 700"
                        >({{ totalDays(warrantyArr) }} Days)</span
                      ></b
                    >
                  </label>
                </td>
              </tr>
              <!-- <tr>
                  <td valign="middle" class="py-1 font-size-18 font-weight-600">
                    Duration
                  </td>
                  <td valign="middle" class="py-1 font-size-18 font-weight-500">
                    <label class="m-0"> 4(Days) </label>
                  </td>
                </tr> -->
              <!-- <tr>
                <td
                  valign="middle"
                  class="py-1 font-size-18 font-weight-600"
                  style="width: 140px"
                >
                  Description
                </td>
                <td
                  valign="middle"
                  class="py-1 font-size-18 font-weight-500 d-flex"
                >
                  <label class="m-0" v-if="warrantyArr && warrantyArr.remark">
                    {{ warrantyArr.remark }}
                  </label>
                  <em v-else class="text--secondary"> no Description </em>
                </td>
              </tr> -->
              <tr v-if="warrantyArr && warrantyArr.terminate_date">
                <td valign="middle" class="py-2 font-size-18 font-weight-500">
                  Terminate Date
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <label
                    class="m-0"
                    v-if="warrantyArr && warrantyArr.terminate_date"
                  >
                    {{ formatDate(warrantyArr.terminate_date) }}
                  </label>
                  <em v-else class="text--secondary"> no end date </em>
                </td>
              </tr>
              <tr v-if="warrantyArr && warrantyArr.terminate_reason">
                <td
                  valign="middle"
                  class="py-2 font-size-18 font-weight-500"
                  style="width: 41px"
                >
                  Terminate Reason
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <label
                    class="m-0"
                    style="color: red !important"
                    v-if="warrantyArr && warrantyArr.terminate_reason"
                  >
                    {{ warrantyArr.terminate_reason }}
                  </label>
                  <em v-else class="text--secondary"> no Terminate Reason </em>
                </td>
              </tr>
            </table>
          </div>
          <div class="" v-if="false">
            <span
              class="gray-background py-1 px-2 d-block color-custom-blue font-weight-700 font-size-16"
            >
              Asset Detail
            </span>
            <table width="100%">
              <tr>
                <td
                  valign="middle"
                  class="py-2 font-size-18 font-weight-500"
                  width="100"
                >
                  Asset #
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <v-chip
                    small
                    label
                    color="chip-custom-blue"
                    outlined
                    class="text-white p-3 mr-2"
                    text-color=""
                    v-on:click="detailEquipmint(warrantyArr?.equipment?.id)"
                    v-if="warrantyArr && warrantyArr.equipment"
                  >
                    {{ warrantyArr.equipment.barcode }}
                  </v-chip>
                  <em v-else class="text--secondary"> no equipment #</em>
                  <showBarcode
                    :barcodeUrl="
                      warrantyArr &&
                      warrantyArr.equipment &&
                      warrantyArr.equipment.barcode_image
                    "
                    title="Asset Barcode"
                  ></showBarcode>
                </td>
              </tr>
              <tr>
                <td
                  valign="middle"
                  class="py-2 font-size-18 font-weight-500"
                  width="100"
                >
                  Name
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <span
                    v-if="
                      warrantyArr &&
                      warrantyArr.equipment &&
                      warrantyArr.equipment.name
                    "
                  >
                    {{ warrantyArr.equipment.name }}
                  </span>
                  <em v-else class="text--secondary"> no name</em>
                </td>
              </tr>
              <tr>
                <td valign="middle" class="py-2 font-size-18 font-weight-500">
                  Asset Serial No.
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <template
                    v-if="
                      warrantyArr &&
                      warrantyArr.equipment &&
                      warrantyArr.equipment.serial_no
                    "
                  >
                    <v-chip color="blue darken-4 mr-2" label small outlined>
                      {{ warrantyArr.equipment.serial_no }}
                    </v-chip>
                    <showBarcode
                      :barcodeUrl="warrantyArr.equipment.serial_image"
                      :barcode-text="warrantyArr.equipment.serial_no"
                      title="Asset Serial No"
                    />
                  </template>
                  <template v-else> no Asset Serial No. </template>
                </td>
              </tr>
              <tr>
                <td valign="middle" class="py-2 font-size-18 font-weight-500">
                  Selling Price
                </td>
                <td valign="middle" class="py-2 font-size-18">
                  <template v-if="warrantyArr.equipment.selling_price">
                    <!-- <span class="text-capitalize">{{ formatMoney(detail.selling_price) }}</span> -->
                    <v-chip outlined text-color="" label small color="blue">
                      {{ formatMoney(warrantyArr.equipment.selling_price) }}
                    </v-chip>
                  </template>
                  <template v-else> no Selling Price </template>
                </td>
              </tr>
            </table>
          </div>
        </v-card>
      </template>
      <template v-slot:header-title>
        <div class="d-flex align-center">
          <span
            class="color-custom-blue font-weight-700 font-size-20 mr-2"
            v-if="
              warrantyArr &&
              warrantyArr.customer &&
              warrantyArr.customer.display_name
            "
            >{{ warrantyArr.customer.display_name }}</span
          >
          <!-- <v-chip
            label
            color="chip-custom-blue"
            outlined
            class="text-white p-3 mr-2"
            text-color=""
            style="font-size: 21px !important; font-weight: 700"
          >
            {{ warrantyArr.unique_id }}
          </v-chip> -->
          <!-- <v-chip
            :color="getColor(warrantyArr.status)"
            label
            v-if="warrantyArr.status"
          >
            <span class="font-size-16 font-weight-500">
              {{ getStatus(warrantyArr.status) }}
            </span>
          </v-chip>
          <v-chip
            v-if="
              warrantyArr &&
              warrantyArr.equipment &&
              warrantyArr.equipment.main_type == 'main_company'
            "
            label
            color="#0d47a1"
            outlined
            class="p-3 ml-2"
          >
            Own Equipment
          </v-chip> -->
        </div>
      </template>
      <template v-slot:header-action>
        <div class="d-flex align-center justify-end">
          <template
            v-if="
              !warrantyArr.is_renewed &&
              warrantyArr.status != 1 &&
              warrantyArr.status != 4
            "
          >
            <v-tooltip bottom content-class="custom-bottom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="getWarranty('renew')"
                >
                  <v-icon left>mdi-orbit-variant</v-icon> Renew
                </v-btn>
              </template>
              <span>Renew Warranty</span>
            </v-tooltip>
          </template>
          <template
            v-if="
              warrantyArr.status != 4 &&
              warrantyArr.status != 1 &&
              !warrantyArr.is_renewed
            "
          >
            <v-tooltip bottom content-class="custom-bottom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="orange"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="getWarranty('extend')"
                >
                  <v-icon left>mdi-update</v-icon> Extend
                </v-btn>
              </template>
              <span>Extend Warranty</span>
            </v-tooltip>
          </template>
          <template
            v-if="
              warrantyArr.status != 4 &&
              warrantyArr.status != 1 &&
              warrantyArr.status != 5
            "
          >
            <v-tooltip bottom content-class="custom-bottom-tooltip">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  depressed
                  :disabled="pageLoading"
                  class="mx-2 custom-bold-button white--text"
                  color="red"
                  v-bind="attrs"
                  v-on="on"
                  v-on:click="terminateWarranty"
                >
                  <v-icon left>mdi-update</v-icon> Terminate
                </v-btn>
              </template>
              <span>Terminate Warranty</span>
            </v-tooltip>
          </template>
          <v-btn
            depressed
            class="mx-2 custom-grey-border custom-bold-button"
            v-on:click="goBack()"
          >
            <v-icon small left>mdi-keyboard-backspace</v-icon>
            Back
          </v-btn>
        </div>
      </template>
      <template v-slot:body>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll"
          style="
            max-height: calc(100vh - 185px);
            height: calc(100vh - 185px);
            position: relative;
          "
        >
          <v-container fluid class="px-0">
            <v-row>
              <v-col md="6" v-if="false">
                <div class="new-white-box-overview-main nav">
                  <div class="col-md-5 customer-image text-center">
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="image"
                    >
                    </v-skeleton-loader>
                    <template v-else>
                      <v-img
                        :lazy-src="$defaultImage"
                        :src="
                          warrantyArr &&
                          warrantyArr.equipment &&
                          warrantyArr.equipment.image
                        "
                        aspect-ratio="1"
                        max-height="200"
                        max-width="200"
                        class="margin-auto custom-grey-border custom-border-radius-50"
                        transition="fade-transition"
                      >
                      </v-img>
                    </template>
                  </div>

                  <div class="col-md-7 gray-background pt-0">
                    <v-skeleton-loader
                      class="custom-skeleton"
                      v-if="pageLoading"
                      type="text@5"
                    >
                    </v-skeleton-loader>

                    <table v-else class="width-100">
                      <span
                        class="color-custom-blue font-weight-700 font-size-19 mb-5"
                      >
                        Asset Detail
                        <div
                          v-if="warrantyArr && warrantyArr.equipment"
                          class="d-flex"
                        >
                          <v-chip
                            label
                            :color="
                              warrantyArr.equipment.main_type == 'main_customer'
                                ? '#4CAF50'
                                : '#0D47A1'
                            "
                            class="text-white p-3 mb-1"
                            small
                          >
                            <template
                              v-if="
                                warrantyArr.equipment.main_type ==
                                'main_customer'
                              "
                            >
                              Customer
                            </template>
                            <template v-else> Company </template>
                          </v-chip>
                          <v-chip
                            label
                            :color="
                              warrantyArr.equipment.type == 'personal'
                                ? 'cyan'
                                : 'red'
                            "
                            outlined
                            small
                            class="text-white p-3 mr-3"
                          >
                            <template
                              v-if="warrantyArr.equipment.type == 'personal'"
                            >
                              Customer/Own
                            </template>
                            <template v-else> {{ appCompanyName }} </template>
                          </v-chip>
                        </div>
                      </span>
                      <template>
                        <tr>
                          <td
                            class="pb-2 font-size-18 text-capitalize"
                            style="width: 160px"
                          >
                            Asset Name
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                          >
                            <span
                              class="fw-500 no-wrap"
                              v-if="
                                warrantyArr &&
                                warrantyArr.equipment &&
                                warrantyArr.equipment.name
                              "
                            >
                              {{ warrantyArr.equipment.name }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Serial no
                          </td>
                          <!--   <pre>{{ customer }}</pre> -->
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.serial_no
                            "
                          >
                            {{ warrantyArr.equipment.serial_no }}
                          </td>
                          <em v-else class="text-muted"> no Serial no</em>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Model
                          </td>
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.model
                            "
                          >
                            <span class="fw-500 no-wrap">
                              {{ warrantyArr.equipment.model }}
                            </span>
                          </td>
                          <em v-else class="text-muted"> no model</em>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Brand
                          </td>
                          <!--   <pre>{{ customer }}</pre> -->
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.brand &&
                              warrantyArr.equipment.brand
                            "
                          >
                            {{ warrantyArr.equipment.brand }}
                          </td>
                          <em v-else class="text-muted"> no Brand</em>
                        </tr>
                        <tr>
                          <td class="pb-2 font-size-18 text-capitalize">
                            Location
                          </td>
                          <!--   <pre>{{ customer }}</pre> -->
                          <td
                            class="pb-2 font-size-18 font-weight-600 text-capitalize"
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.location &&
                              warrantyArr.equipment.location.text
                            "
                          >
                            {{ warrantyArr.equipment.location.text }}
                          </td>
                          <em v-else class="text-muted"> no Location</em>
                        </tr>
                      </template>
                    </table>
                  </div>
                </div>
              </v-col>

              <v-col md="12" class="pt-0">
                <v-tabs
                  active-class="custom-tab-active"
                  v-model="contractTab"
                  background-color="transparent"
                  color="cyan"
                  class="custom-tab-transparent tab-sticky"
                >
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#overview"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/product.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Overview
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#extension"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/product.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Extension
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#attachment"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        :src="$assetURL('media/custom-svg/attachment.svg')"
                      />
                      <!--end::Svg Icon-->
                    </span>
                    Attachment
                  </v-tab>
                  <v-tab
                    class="font-size-16 font-weight-600 px-8"
                    href="#history"
                  >
                    <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon">
                      <inline-svg
                        :src="$assetURL('media/custom-svg/history.svg')"
                      />
                    </span>
                    History
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="contractTab">
                  <v-tab-item value="attachment">
                    <AdditionalDocuments
                      :type-id="warrantyArr.id"
                      class="mx-4"
                      type-text="Warranty Attachments"
                      :type="115"
                      :extensive="false"
                    ></AdditionalDocuments>
                  </v-tab-item>
                  <v-tab-item value="overview">
                    <v-layout class="my-4">
                      <v-flex md6 class="mr-2">
                        <table class="width-100">
                          <template v-if="warrantyArr && warrantyArr.equipment">
                            <tr>
                              <td colspan="2">
                                <span
                                  class="color-custom-blue font-weight-700 font-size-16 pb-5"
                                >
                                  {{
                                    get_equp_type(
                                      warrantyArr?.equipment?.equipment_type
                                    )
                                  }}
                                  Detail
                                  {{ warrantyArr?.equipment?.equipment_type }}
                                  <div
                                    v-if="
                                      !warrantyArr?.equipment?.equipment_type
                                    "
                                    class="d-flex mt-4"
                                  >
                                    <v-chip
                                      label
                                      :color="
                                        warrantyArr.equipment.main_type ==
                                        'main_customer'
                                          ? '#4CAF50'
                                          : '#0D47A1'
                                      "
                                      class="text-white p-3 mr-3 mb-1"
                                      small
                                    >
                                      <template
                                        v-if="
                                          warrantyArr.equipment.main_type ==
                                          'main_customer'
                                        "
                                      >
                                        Customer
                                      </template>
                                      <template v-else> Company </template>
                                    </v-chip>
                                    <v-chip
                                      label
                                      :color="
                                        warrantyArr.equipment.type == 'personal'
                                          ? 'cyan'
                                          : 'red'
                                      "
                                      outlined
                                      small
                                      class="text-white p-3 mr-3"
                                    >
                                      <template
                                        v-if="
                                          warrantyArr.equipment.type ==
                                          'personal'
                                        "
                                      >
                                        Customer/Own
                                      </template>
                                      <template v-else>
                                        {{ appCompanyName }}
                                      </template>
                                    </v-chip>
                                  </div>
                                </span>
                              </td>
                            </tr>
                            <template>
                              <tr>
                                <td
                                  valign="middle"
                                  class="py-2 font-size-18 font-weight-500"
                                  width="100"
                                >
                                  Type
                                </td>
                                <td valign="middle" class="py-2 font-size-18">
                                  <v-chip
                                    small
                                    label
                                    :color="
                                      get_equp_type_color(
                                        warrantyArr?.equipment?.equipment_type
                                      )
                                    "
                                    text-color=""
                                    outlined
                                  >
                                    {{
                                      get_equp_type(
                                        warrantyArr?.equipment?.equipment_type
                                      )
                                    }}
                                  </v-chip>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                  width="100"
                                >
                                  {{
                                    get_equp_type(
                                      warrantyArr?.equipment?.equipment_type
                                    )
                                  }}
                                  #
                                </td>
                                <td valign="middle" class="py-1 font-size-18">
                                  <v-chip
                                    small
                                    label
                                    color="chip-custom-blue"
                                    outlined
                                    class="text-white p-3 mr-2"
                                    text-color=""
                                    @click="
                                      viewEquipment(warrantyArr.equipment)
                                    "
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.barcode
                                    "
                                  >
                                    {{ warrantyArr.equipment.barcode }}
                                  </v-chip>
                                  <em v-else class="text--secondary">
                                    no equipment #</em
                                  >
                                  <showBarcode
                                    :barcodeUrl="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.barcode_image &&
                                      warrantyArr.equipment.barcode_image
                                    "
                                    title="Asset barcode"
                                  ></showBarcode>
                                  <v-chip
                                    :color="getStatusColor('Faulty')"
                                    label
                                    small
                                    text-color="white"
                                    class="mr-2"
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.faulty > 0
                                    "
                                  >
                                    Faulty
                                  </v-chip>
                                  <v-chip
                                    :color="getStatusColor('Retired')"
                                    label
                                    text-color="white"
                                    class="mr-2"
                                    small
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.retired > 0
                                    "
                                  >
                                    Retired
                                  </v-chip>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  valign="middle"
                                  class="py-1 font-size-18 font-weight-500"
                                >
                                  Serial No
                                </td>
                                <td valign="middle" class="py-1 font-size-18">
                                  <span
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment.serial_no
                                    "
                                  >
                                    {{ warrantyArr.equipment.serial_no }}
                                  </span>
                                  <em v-else class="text--secondary">
                                    no Serial No</em
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="py-1 font-size-18 text-capitalize font-weight-500"
                                  style="width: 160px"
                                >
                                  Name
                                </td>
                                <td class="py-1 font-size-18 text-capitalize">
                                  <span
                                    class="fw-500 no-wrap"
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.name
                                    "
                                  >
                                    {{ warrantyArr.equipment.name }}
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="py-1 font-size-18 text-capitalize font-weight-500"
                                >
                                  Model
                                </td>
                                <td class="py-1 font-size-18 text-capitalize">
                                  <span
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.model
                                    "
                                    class="fw-500 no-wrap"
                                  >
                                    {{ warrantyArr.equipment.model }}
                                  </span>
                                  <em v-else class="text-muted"> no model</em>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="py-1 font-size-18 text-capitalize font-weight-500"
                                >
                                  Brand
                                </td>
                                <!--   <pre>{{ customer }}</pre> -->
                                <td class="py-1 font-size-18 text-capitalize">
                                  <template
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.brand &&
                                      warrantyArr.equipment.brand
                                    "
                                  >
                                    {{ warrantyArr.equipment.brand }}
                                  </template>
                                  <em v-else class="text-muted"> no Brand</em>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="py-1 font-size-18 text-capitalize font-weight-500"
                                >
                                  Selling Price
                                </td>
                                <!--   <pre>{{ customer }}</pre> -->
                                <td class="py-1 font-size-18 text-capitalize">
                                  <template
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.selling_price &&
                                      warrantyArr.equipment.selling_price
                                    "
                                  >
                                    $
                                    {{ warrantyArr.equipment.selling_price }}.00
                                  </template>
                                  <em v-else class="text-muted">
                                    no selling price</em
                                  >
                                </td>
                              </tr>
                              <tr>
                                <td
                                  class="py-1 font-size-18 text-capitalize font-weight-600"
                                >
                                  Location
                                </td>
                                <!--   <pre>{{ customer }}</pre> -->
                                <td class="py-1 font-size-18 text-capitalize">
                                  <span
                                    v-if="
                                      warrantyArr &&
                                      warrantyArr.equipment &&
                                      warrantyArr.equipment.location &&
                                      warrantyArr.equipment.location.text
                                    "
                                  >
                                    {{ warrantyArr.equipment.location.text }}
                                  </span>
                                  <em v-else class="text-muted">
                                    no Location</em
                                  >
                                </td>
                              </tr>
                            </template>
                          </template>
                        </table>
                      </v-flex>
                      <v-flex md6 class="mr-2">
                        <v-card
                          flat
                          class="custom-grey-border remove-border-radius mt-4 h-100"
                        >
                          <v-card-title class="headline grey lighten-4 py-1">
                            <span
                              class="font-weight-700 custom-headline color-custom-blue font-size-16"
                            >
                              Description</span
                            >
                          </v-card-title>
                          <v-card-text class="p-6 font-size-16">
                            <label
                              class="m-0"
                              v-if="warrantyArr && warrantyArr.remark"
                            >
                              {{ warrantyArr.remark }}
                            </label>
                            <em v-else class="text--secondary">
                              no Description
                            </em>
                          </v-card-text>
                        </v-card>
                      </v-flex>
                    </v-layout>
                    <v-layout class="my-4">
                      <v-flex md5 class="mr-2">
                        <table class="width-100">
                          <span
                            class="color-custom-blue font-weight-700 font-size-19 mb-4"
                          >
                            <template
                              v-if="
                                warrantyArr &&
                                warrantyArr.equipment &&
                                warrantyArr.equipment.main_type ==
                                  'main_customer'
                              "
                            >
                              Customer Detail</template
                            >
                            <template v-else>
                              <template v-if="warrantyArr && warrantyArr.entity"
                                >Site Location</template
                              >
                            </template>
                          </span>
                          <template
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.main_type == 'main_customer'
                            "
                          >
                            <tr>
                              <td
                                class="pb-2 font-size-18 text-capitalize font-weight-500"
                                style="width: 200px"
                              >
                                Type
                              </td>
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer
                                "
                              >
                                <span class="fw-500 no-wrap">
                                  <v-chip
                                    color="blue white--text"
                                    small
                                    label
                                    v-if="
                                      warrantyArr.equipment.customer
                                        .company_type == 'company'
                                    "
                                  >
                                    <span
                                      class="font-size-16 text-uppercase font-weight-500"
                                    >
                                      Company
                                    </span>
                                  </v-chip>
                                  <v-chip
                                    color="red white--text"
                                    label
                                    v-if="
                                      warrantyArr.equipment.customer
                                        .company_type == 'personal'
                                    "
                                  >
                                    <span class="font-size-16 font-weight-500">
                                      Individual
                                    </span>
                                  </v-chip>
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td
                                valign="top"
                                class="pb-2 font-size-18 text-capitalize font-weight-500"
                              >
                                Display Name
                              </td>
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.display_name
                                "
                              >
                                <span class="no-wrap">
                                  {{
                                    warrantyArr.equipment.customer.display_name
                                  }}
                                </span>
                              </td>
                              <td v-else>
                                <em class="text-muted"> no Display Name</em>
                              </td>
                            </tr>
                            <tr>
                              <td
                                valign="top"
                                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                              >
                                Company Name
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_name
                                "
                              >
                                {{
                                  warrantyArr.equipment.customer.company_name
                                }}
                              </td>
                              <td v-else>
                                <em class="text-muted"> no Company Name</em>
                              </td>
                            </tr>
                            <tr>
                              <td
                                valign="top"
                                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                              >
                                Company Email
                              </td>
                              <td
                                class="pb-2 font-size-18"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_email
                                "
                              >
                                <span class="fw-500 no-wrap">
                                  {{
                                    warrantyArr.equipment.customer.company_email
                                  }}
                                </span>
                              </td>
                              <td v-else>
                                <em class="text-muted"> no Company Email</em>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="pb-2 font-size-18 text-capitalize font-weight-500"
                              >
                                Company Number
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_number
                                "
                              >
                                {{
                                  warrantyArr.equipment.customer.company_number
                                }}
                              </td>
                              <td v-else>
                                <em class="text-muted"> no Company Number</em>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="pb-2 font-size-18 text-capitalize font-weight-500"
                              >
                                Company Fax
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.company_fax
                                "
                              >
                                {{ warrantyArr.equipment.customer.company_fax }}
                              </td>
                              <td v-else>
                                <em class="text-muted"> no Company Fax</em>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                              >
                                UEN
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.uen
                                "
                              >
                                {{ warrantyArr.equipment.customer.uen }}
                              </td>
                              <td v-else>
                                <em class="text-muted"> no UEN</em>
                              </td>
                            </tr>
                            <tr>
                              <td
                                class="pb-2 font-size-18 font-weight-500 text-capitalize"
                              >
                                Website
                              </td>
                              <!--   <pre>{{ customer }}</pre> -->
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                v-if="
                                  warrantyArr &&
                                  warrantyArr.equipment &&
                                  warrantyArr.equipment.customer &&
                                  warrantyArr.equipment.customer.website
                                "
                              >
                                {{ warrantyArr.equipment.customer.website }}
                              </td>
                              <td v-else>
                                <em class="text-muted"> no Website</em>
                              </td>
                            </tr>
                          </template>
                          <template v-else>
                            <tr>
                              <td
                                class="pb-2 font-size-18 text-capitalize"
                                style="width: 200px"
                              >
                                <span class="font-weight-700"
                                  ><template
                                    v-if="warrantyArr && warrantyArr.entity"
                                  >
                                    {{ getFormattedCompany() }}
                                  </template></span
                                >
                              </td>
                            </tr>
                            <div class="gray-background pt-0">
                              <v-skeleton-loader
                                class="custom-skeleton"
                                v-if="pageLoading"
                                type="text@5"
                              >
                              </v-skeleton-loader>
                            </div>
                          </template>
                        </table>
                      </v-flex>
                      <v-flex
                        md3
                        class="mr-2 custom-border-right"
                        v-if="
                          warrantyArr &&
                          warrantyArr.equipment &&
                          warrantyArr.equipment.main_type == 'main_customer'
                        "
                      >
                        <table width="100%">
                          <tr>
                            <td class="font-weight-700 font-size-18">
                              Contact details
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property &&
                              warrantyArr.equipment.property.contact_person
                            "
                          >
                            <td class="py-0 font-size-18">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property.contact_person
                                    .display_name
                                "
                              >
                                <v-icon small>mdi-account</v-icon></span
                              >
                              {{
                                warrantyArr.equipment.property.contact_person
                                  .display_name
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property &&
                              warrantyArr.equipment.property.contact_person
                            "
                          >
                            <td class="py-0 font-size-18">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property.contact_person
                                    .primary_phone
                                "
                              >
                                <v-icon small>mdi-phone</v-icon></span
                              >
                              {{
                                warrantyArr.equipment.property.contact_person
                                  .primary_phone
                              }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property &&
                              warrantyArr.equipment.property.contact_person
                            "
                          >
                            <td class="py-0 font-size-18">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property.contact_person
                                    .primary_email
                                "
                              >
                                <v-icon small>mdi-email</v-icon></span
                              >
                              {{
                                warrantyArr.equipment.property.contact_person
                                  .primary_email
                              }}
                            </td>
                          </tr>
                          <tr v-else class="py-0 font-size-18 font-weight-500">
                            <em class="text-muted ml-3"> no email address</em>
                          </tr>
                        </table>

                        <table width="100%" class="mt-4">
                          <tr>
                            <td class="font-weight-700 font-size-18">
                              <label>Company Address</label>
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr &&
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property
                            "
                          >
                            <td class="py-0 d-flex">
                              <span
                                class="mr-2"
                                v-if="
                                  warrantyArr.equipment.property
                                    .property_address
                                "
                              >
                                <v-icon small>mdi-map-marker</v-icon></span
                              >
                              <label
                                v-if="
                                  warrantyArr && warrantyArr.equipment.property
                                "
                                ><template
                                  v-if="warrantyArr.equipment.property.street_1"
                                  >{{
                                    warrantyArr.equipment.property.street_1
                                  }},</template
                                >
                                <br
                                  v-if="
                                    warrantyArr.equipment.property.street_2 ||
                                    warrantyArr.equipment.property.unit_no
                                  "
                                />
                                <template
                                  v-if="warrantyArr.equipment.property.street_2"
                                >
                                  {{ warrantyArr.equipment.property.street_2 }},
                                </template>
                                <template
                                  v-if="warrantyArr.equipment.property.unit_no"
                                >
                                  {{ warrantyArr.equipment.property.unit_no }},
                                </template>
                                <br />
                                {{ warrantyArr.equipment.property.country }},
                                {{ warrantyArr.equipment.property.zip_code }}
                              </label>
                            </td>
                          </tr>
                          <em v-else class="text-muted ml-3">
                            no company address</em
                          >
                        </table>
                      </v-flex>
                      <v-flex
                        md3
                        class="ml-2"
                        v-if="
                          warrantyArr &&
                          warrantyArr.equipment &&
                          warrantyArr.equipment.main_type == 'main_customer'
                        "
                      >
                        <table width="100%">
                          <tr>
                            <td class="font-weight-700 font-size-18">
                              Site Location Contact Details
                            </td>
                          </tr>
                          <!--    <pre>{{warrantyArr}}</pre> -->
                          <tr v-if="warrantyArr">
                            <td class="py-0 font-size-18">
                              <span
                                class="mr-2"
                                v-if="warrantyArr.location_person_display_name"
                              >
                                <v-icon small>mdi-account</v-icon></span
                              >
                              {{ warrantyArr.location_person_display_name }}
                            </td>
                          </tr>
                          <tr
                            v-if="
                              warrantyArr &&
                              warrantyArr.location_person_primary_phone
                            "
                          >
                            <td class="py-0 font-size-18">
                              <span class="mr-2">
                                <v-icon small>mdi-phone</v-icon></span
                              >
                              {{ warrantyArr.location_person_primary_phone }}
                            </td>
                          </tr>
                          <!-- <tr v-else>
                            <td class="py-0 font-size-18 font-weight-500">
                              <em class="text-muted ml-3"> no phone number</em>
                            </td>
                          </tr> -->
                          <tr
                            v-if="
                              warrantyArr.equipment &&
                              warrantyArr.equipment.property &&
                              warrantyArr.equipment.property.contact_person
                            "
                          >
                            <td class="py-0 font-size-18">
                              <span
                                class="mr-2"
                                v-if="warrantyArr.location_person_primary_email"
                              >
                                <v-icon small>mdi-email</v-icon></span
                              >
                              {{ warrantyArr.location_person_primary_email }}
                            </td>
                          </tr>
                          <!--  <tr v-else class="py-0 font-size-18 font-weight-500">
                            <em class="text-muted ml-3"> no email address</em>
                          </tr> -->
                        </table>
                        <table width="100%" class="mt-4">
                          <tr>
                            <td class="font-weight-700 font-size-18">
                              <label>Site Location Company Address</label>
                            </td>
                          </tr>
                          <tr v-if="warrantyArr">
                            <td class="py-0 d-flex">
                              <span class="mr-2" v-if="warrantyArr">
                                <v-icon small>mdi-map-marker</v-icon></span
                              >
                              <label v-if="warrantyArr"
                                ><template v-if="warrantyArr.location_street_1">
                                  <span
                                    v-if="warrantyArr?.location_property_name"
                                    >{{ warrantyArr?.location_property_name
                                    }}<br
                                  /></span>
                                  {{ warrantyArr.location_street_1 }},</template
                                >
                                <br
                                  v-if="
                                    warrantyArr.location_street_2 ||
                                    warrantyArr.location_unit_no
                                  "
                                />
                                <template v-if="warrantyArr.location_street_2">
                                  {{ warrantyArr.location_street_2 }},
                                </template>
                                <template v-if="warrantyArr.location_unit_no">
                                  {{ warrantyArr.location_unit_no }},
                                </template>
                                <br />
                                {{ warrantyArr.location_country }},
                                {{ warrantyArr.location_zip_code }}
                              </label>
                            </td>
                          </tr>
                          <!--  <em v-else class="text-muted ml-3">
                            no company address</em
                          > -->
                        </table>
                      </v-flex>
                    </v-layout>
                  </v-tab-item>
                  <v-tab-item :value="'extension'">
                    <ExtensionLog :related-id="warrantyArr.id"></ExtensionLog>
                  </v-tab-item>
                  <v-tab-item
                    v-if="getPermission('history:view')"
                    :value="'history'"
                  >
                    <div class="service-detail-search-height">
                      <InternalHistoryDetail
                        type="warranty"
                        :type_id="warrantyArr.id"
                      ></InternalHistoryDetail>
                    </div>
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-container>
        </perfect-scrollbar>
      </template>
      <template v-slot:footer> </template>
    </DetailTemplate>
    <ExtentWarranty
      v-if="extentWarrantyDialog"
      :warrantyDetail="warrantyArr"
      :dialogStatus="extentWarrantyDialog"
      v-on:close:dialog="extentWarrantyDialog = false"
      v-on:update:warranty="getWarranty"
    ></ExtentWarranty>
    <RenewWarranty
      v-if="renewWarrantyDialog"
      :warrantyDetail="warrantyArr"
      :dialogStatus="renewWarrantyDialog"
      v-on:close:dialog="renewWarrantyDialog = false"
      v-on:update:warranty="getWarranty"
    ></RenewWarranty>
    <TerminatWarranty
      v-if="teminateWarrantyDialog"
      :warrantyDetail="warrantyArr"
      :dialogStatus="teminateWarrantyDialog"
      v-on:close:dialog="teminateWarrantyDialog = false"
      v-on:update:warranty="getWarranty"
    ></TerminatWarranty>
  </div>
</template>
<script>
import DetailTemplate from "@/view/pages/partials/Detail-Template.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { GET /* , PATCH */ } from "@/core/services/store/request.module";
import AdditionalDocuments from "@/view/pages/engineer/Additional-Documents";
import ExtentWarranty from "@/view/pages/warranty/Extended-Warranty.vue";
import RenewWarranty from "@/view/pages/warranty/Renew-Warranty.vue";
import ExtensionLog from "@/view/pages/warranty/Extension-Log.vue";
import TerminatWarranty from "@/view/pages/warranty/Terminat-Warranty.vue";
import moment from "moment";
import InternalHistoryDetail from "@/view/pages/partials/Detail/Internal-History-Detail.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import showBarcode from "@/view/components/showBarcode.vue";

export default {
  mixins: [CommonMixin, ValidationMixin],
  name: "customer-detail",
  title: "Customer",
  data() {
    return {
      pageLoading: false,
      contractTab: "overview",
      warranty: 0,
      extentWarrantyDialog: false,
      renewWarrantyDialog: false,
      teminateWarrantyDialog: false,
      warrantyArr: {},
    };
  },
  components: {
    DetailTemplate,
    AdditionalDocuments,
    showBarcode,
    ExtentWarranty,
    RenewWarranty,
    TerminatWarranty,
    InternalHistoryDetail,
    ExtensionLog,
  },
  methods: {
    get_equp_type(type) {
      if (type == "asset") {
        return "Own Asset";
      } else if (type == "customer-asset") {
        return "Customer Asset";
      } else if (type == "tool") {
        return "Tool";
      } else {
        return "Customer Asset";
      }
    },
    get_equp_type_color(type) {
      if (type == "asset") {
        return "red";
      } else if (type == "customer-asset") {
        return "blue";
      } else if (type == "tool") {
        return "green";
      } else {
        return "green";
      }
    },
    terminateWarranty() {
      this.teminateWarrantyDialog = true;
    },
    getStatusColor(status = "") {
      let color = "cyan";
      switch (status) {
        case "Active":
          color = "green";
          break;
        case "In-Active":
          color = "red";
          break;
        case "Faulty":
          color = "orange";
          break;
        case "Retired":
          color = "red";
          break;
      }
      return color;
    },

    viewEquipment(params) {
      let routeName = null;
      if (params.equipment_type == "customer-asset") {
        routeName = "client-equipment.detail";
      } else if (params.equipment_type == "tool") {
        routeName = "equipment.detail";
      } else if (params.equipment_type == "asset") {
        routeName = "asset-equipment.detail";
      } else if (params.equipment_type == "goods") {
        routeName = "product.detail";
      } else if (params.equipment_type == "service") {
        routeName = "service.detail";
      }
      this.$router.push(
        this.getDefaultRoute(routeName, {
          params: { id: params.id },
        })
      );
    },
    remaningDate(date) {
      var a = moment(date.end_date);
      var b = moment(date.start_date);

      var years = a.diff(b, "year");
      b.add(years, "years");

      var months = a.diff(b, "months");
      b.add(months, "months");

      var days = a.diff(b, "days");
      if (days == 0) {
        days = "";
      } else {
        days = days + " Days";
      }
      if (months == 0) {
        months = "";
      } else {
        months = months + " Months";
      }
      if (years == 0) {
        years = "";
      } else {
        years = years + " Years";
      }

      return years + " " + months + " " + days;
    },
    getFormattedCompany() {
      let tempArr = [];
      if (this.warrantyArr.entity.unit_no) {
        tempArr.push(this.warrantyArr.entity.unit_no);
      }
      if (this.warrantyArr.entity.street_1) {
        tempArr.push(this.warrantyArr.entity.street_1);
      }
      if (this.warrantyArr.entity.street_2) {
        tempArr.push(this.warrantyArr.entity.street_2);
      }

      if (this.warrantyArr.entity.zip_code) {
        tempArr.push(this.warrantyArr.entity.zip_code);
      }
      if (this.warrantyArr.entity.landmark) {
        tempArr.push(this.warrantyArr.entity.landmark);
      } else {
        tempArr.push("Singapore");
      }
      /*   if (this.lodash.isEmpty(row.city) === false) {
        tempArr.push(row.city.name);
      }
      if (this.lodash.isEmpty(row.state) === false) {
        tempArr.push(row.state.name);
      }
      if (this.lodash.isEmpty(row.country) === false) {
        tempArr.push(row.country);
      } */
      return tempArr.join(", ");
    },
    /*   totalDays(date) {
      var a = moment(date.end_date);
      var b = moment(date.start_date);
      var final = a.diff(b, "days"); // =1
      return final;
    }, */
    totalDays(date) {
      let today = moment().format("YYYY-MM-DD");

      var a = moment(date.end_date);
      var b = moment(today); //date.start_date
      var final = a.diff(b, "days"); // =1
      if (final <= 0) {
        final = 0;
      }
      return final;
    },
    getStatus(status) {
      if (status == 1) {
        return "Draft";
      } else if (status == 2) {
        return "Active";
      } else if (status == 3) {
        return "Extended";
      } else if (status == 4) {
        return "Terminated";
      } else if (status == 5) {
        return "Expired";
      } else if (status == 6) {
        return "Renewed";
      }
    },
    detailEquipmint(param) {
      this.$router.push(
        this.getDefaultRoute("client-equipment.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    getColor(status) {
      if (status == 1) {
        return "cyan white--text";
      } else if (status == 2) {
        return "green white--text";
      } else if (status == 3) {
        return "orange white--text";
      } else if (status == 4) {
        return "red white--text";
      } else if (status == 5) {
        return "teal white--text";
      } else if (status == 6) {
        return "blue white--text";
      }
    },
    getWarranty(type) {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "warranty/" + _this.warranty,
        })
        .then(({ data }) => {
          _this.warrantyArr = data;
          if (type && type == "extend") {
            this.extentWarrantyDialog = true;
          }
          if (type && type == "renew") {
            this.renewWarrantyDialog = true;
          }
          _this.$store.dispatch(SET_BREADCRUMB, [
            { title: "Warranty", route: "warranty" },
            { title: "Detail" },
            { title: data.barcode },
          ]);
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    if (this.warranty > 0) {
      this.getWarranty();
    }
  },
  created() {
    const _this = this;
    _this.warranty = _this.lodash.toSafeInteger(_this.$route.params.id);
  },
};
</script>
