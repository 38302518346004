<template>
  <v-container fluid>
    <template v-if="!gLoading">
      <div
        :class="`${gModuleTitle[module] ? 'border' : ''}`"
        v-if="formFields.length"
      >
        <div class="py-2 px-3 grey lighten-4" v-if="gModuleTitle[module]">
          <h3 class="font-weight-700 custom-headline color-custom-blue">
            {{ gModuleTitle[module] }}
          </h3>
          <p class="mb-0 text-small mt-1">
            <v-icon small>mdi-progress-question</v-icon>
            Specify the {{ gModuleTitle[module] }} for your {{ module }}
          </p>
        </div>
        <div :class="`${gModuleTitle[module] ? 'py-2 px-3' : ''}`">
          <v-row>
            <template v-for="(form, index) in formFields">
              <template v-if="form.status == 'active'">
                <div
                  v-if="form.start_with_new_line"
                  :key="'br-' + form.label + index"
                  class="w-100"
                ></div>
                <v-col :key="`col-${form.label + index}`" :sm="form.column">
                  <label
                    v-if="!form.hide_label"
                    class="font-size-16 font-weight-500"
                    :class="{
                      required: form.is_mandatory,
                    }"
                  >
                    {{ form.label }}
                  </label>
                  <v-icon
                    v-if="form.instructions"
                    class="ms-2"
                    small
                    :content="`<span class=&quot;font-size-13&quot;>${form.instructions}</span>`"
                    v-tippy="{ arrow: true, animation: 'fade' }"
                    >mdi-progress-question</v-icon
                  >
                  <div>
                    <InputField
                      dense
                      filled
                      solo
                      flat
                      v-model="form.initialVal"
                      :field-type="form.field_type"
                      :placeholder="form.placeholder"
                      :items="form.options"
                      :row="form.row"
                      :row-items="form.row_items"
                      :max="form.max"
                      :min="form.min"
                      :decimal-allow="form.decimal_allow"
                      :max-upload-size="form.max_upload_size"
                      :max-upload-limit="form.max_upload_limit"
                      :valid-file="form.valid_file"
                      hide-details
                      color="cyan"
                      :required="form.is_mandatory"
                      :rules="getRules(form)"
                    />
                  </div>
                </v-col>
              </template>
            </template>
          </v-row>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="py-15 col-md-6 mx-auto">
        <v-progress-linear
          indeterminate
          color="orange"
          height="6"
        ></v-progress-linear>
        <div class="text-center text-h6 mt-3">Loading...</div>
      </div>
    </template>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import {
  GET_FIELD_DATA,
  CLEAR_STATE,
  SET_FORM_DATA,
} from "@/core/services/store/custom.fields.module";
import InputField from "@/view/pages/custom-field-v2/field-properties/c-input-filed.vue";
import ValidationMixin from "@/core/plugins/validation-mixin";
import { cloneDeep, filter } from "lodash";
export default {
  name: "CreateCustomForm",
  mixins: [ValidationMixin],
  props: {
    module: {
      type: String,
      default: null,
    },
    moduleId: {
      type: [Number, String],
      default: null,
    },
    defaultData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  watch: {
    formFields: {
      deep: true,
      handler() {
        this.$nextTick(() => {
          this.emitData();
        });
      },
    },
    gActiveFormFields: {
      deep: true,
      immediate: true,
      handler(param) {
        this.$nextTick(() => {
          const _data = cloneDeep(param);
          if (this.moduleId) {
            this.formFields = filter(
              _data,
              (row) => row.field_type != "attachment"
            );
          } else {
            this.formFields = _data;
          }
        });
      },
    },
  },
  data() {
    return {
      formFields: [],
      timeout: null,
    };
  },
  methods: {
    emitData() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        const _data = this.formFields;
        this.$store.commit(SET_FORM_DATA, _data);
        this.$emit("updated", _data);
      }, 300);
    },
    getRules(form) {
      let rule = [];
      if (form.is_mandatory) {
        rule.push(this.validateRules.required(form.initialVal, form.label));
      }
      if (form.field_type == "email") {
        rule.push(this.validateRules.validEmail(form.initialVal, "Email"));
      }
      return rule;
    },
    getFieldData() {
      this.$store.dispatch(GET_FIELD_DATA, this.module);
    },
  },
  components: {
    InputField,
  },
  computed: {
    ...mapGetters([
      "gLoading",
      "gTotalCount",
      "gFormTitle",
      "gActiveFormFields",
      "gModuleTitle",
    ]),
  },
  beforeDestroy() {
    this.$store.commit(CLEAR_STATE);
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.moduleId) {
        this.getFieldData();
      }
    });
  },
};
</script>
