<template>
  <div class="app-picker-field" @click.prevent="">
    <v-menu
      ref="datepicker"
      v-model="datepicker"
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      max-width="290px"
      min-width="auto"
      :content-class="contentClass"
      :nudge-left="nudgeLeft"
    >
      <template #activator="{ on, attrs }">
        <v-text-field
          :id="_id"
          :label="label"
          :loading="loading"
          clearable
          :disabled="disabled"
          solo
          dense
          flat
          v-model="dateFormatted"
          :placeholder="btxPlaceholder"
          hide-details
          :prepend-inner-icon="'mdi-calendar'"
          v-bind="attrs"
          v-on:blur="date = parseDateArray(dateFormatted)"
          v-on="on"
          readonly
          @click:clear="clearDate"
          @input="$emit('input', $event)"
        ></v-text-field>
      </template>
      <v-card min-width="300" class="date-preset-picker" border>
        <div class="d-flex border-b">
          <div class="flex-grow-1 pa-2 border-e">
            <ul class="range-presets text-sm ps-0">
              <li
                v-for="item in presetList"
                :key="item.value"
                :class="{ active: item.value === selectedPreset }"
                @click="setPresetDate(item.value)"
              >
                {{ item.title }}
              </li>
            </ul>
          </div>
          <div class="pa-2">
            <v-date-picker
              :id="id + '_dp'"
              v-model="date"
              no-title
              range
              reactive
              :min="minDate"
              :type="type"
              :max="maxDate"
            >
            </v-date-picker>
          </div>
        </div>
        <div class="px-2 py-2 d-flex">
          <v-spacer />
          <v-btn
            depressed
            text
            class="mx-2 custom-grey-border custom-bold-button"
            @click="datepicker = false"
          >
            Close
          </v-btn>
          <v-btn
            depressed
            class="custom-grey-border custom-bold-button"
            color="cyan white--text"
            @click="confirmSelection()"
            text
            >Confirm</v-btn
          >
        </div>
      </v-card>

      <!-- <v-date-picker
			v-model="date"
			no-title
			range
			:min="minDate"
			:allowed-dates="disablePastDates"
			:max="maxDate"
		></v-date-picker> -->
    </v-menu>
  </div>
</template>

<script>
import { last, head } from "lodash";
import DateRangeMixin from "@/core/plugins/date-range-mixin";
import moment from "moment";
export default {
  mixins: [DateRangeMixin],
  name: "date-range-picker-v2",
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
    variant: {
      type: String,
      default: "outlined",
    },
    contentClass: {
      type: String,
      default: undefined,
    },
    inlineStyle: {
      type: Object,
      default: () => {},
    },
    minDate: {
      type: [Date, String],
      default: null,
    },
    maxDate: {
      type: [Date, String],
      default: null,
    },
    placeholder: {
      type: String,
      default: "Date",
    },
    id: {
      type: [String, Number],
      default: null,
    },
    label: {
      type: String,
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Number,
      default: 0,
    },
    /* disablePastFrom: {
			type: String,
		}, */
    nudgeLeft: {
      type: [String, Number],
      default: 0,
    },
    customClass: {
      type: [String, Object],
      default: () => {},
    },
    type: {
      type: String,
      default: "date",
    },
  },
  data() {
    return {
      presetList: [
        { title: "Today", value: "today" },
        { title: "This Week", value: "this_week" },
        { title: "Last Week", value: "last_week" },
        { title: "Last 7 Days", value: "last_7_days" },
        { title: "This Month", value: "this_month" },
        { title: "Last Month", value: "last_month" },
        { title: "Last 3 Month", value: "last_3_month" },
        { title: "This Year", value: "this_year" },
      ],
      selectedPreset: null,
      date: [],
      datepicker: false,
      dateFormatted: null,
      stopEmit: false,
    };
  },
  watch: {
    minDate() {
      this.date = [];
    },
    maxDate() {
      this.date = [];
    },
    clear(param) {
      if (param) {
        this.date = null;
        this.$emit("clearDates", false);
      }
    },
    date() {
      const _formatted_date = this.emitDates(this.date);
      this.dateFormatted = this.formatDateGloble(_formatted_date);
      if (this.type == "month") {
        this.$emit("input", this.dateFormatted);
        this.$emit("change", this.dateFormatted);
      } else {
        this.$emit("input", _formatted_date);
        this.$emit("change", _formatted_date);
      }
    },
    value: {
      deep: true,
      handler(param) {
        if (param == null || !param.length) {
          this.dateFormatted = null;
          this.date = null;
          /* this.$emit("click:clear", true); */
        }
      },
    },
  },
  methods: {
    confirmSelection() {
      this.datepicker = false;
      this.emitValue();
    },
    emitValue() {
      this.$nextTick(() => {
        this.$emit("update:model-value", true);
        this.$emit("change", true);
      });
    },
    setPresetDate(type) {
      this.selectedPreset = type;
      let pDate = {};
      switch (type) {
        case "today":
          pDate = this.getToday();
          break;
        case "this_week":
          pDate = this.getThisWeekDates();
          break;
        case "last_week":
          pDate = this.getLastWeekDates();
          break;
        case "last_7_days":
          pDate = this.getLast7DaysDates();
          break;
        case "last_3_month":
          pDate = this.getLast3MonthDates();
          break;
        case "last_month":
          pDate = this.getLastMonthDates();
          break;
        case "this_month":
          pDate = this.getThisMonthDates();
          break;
        case "this_year":
          pDate = this.getThisYearDates();
          break;
      }
      const { startDate, endDate } = pDate;
      this.date = [startDate, endDate];
    },
    formatDateGloble(dates) {
      if (!dates) return null;
      if (this.type == "month") {
        const start = dates.length && dates[0] ? dates[0] : "";
        const end = dates.length && dates[1] ? dates[1] : "";
        if (Boolean(start) && Boolean(end)) {
          const startDate =
            this.MOMENTJS(start).format("MMM YYYY") || head(dates);
          const endDate = this.MOMENTJS(end).format("MMM YYYY") || last(dates);
          return startDate + " - " + endDate;
        } else {
          return "";
        }
      } else {
        const startDate = head(dates);
        const endDate = last(dates);
        const data =
          this.splitDate(startDate) + " - " + this.splitDate(endDate);
        return startDate && endDate ? data : null;
      }
    },
    splitDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDateArray(dates) {
      if (!dates) return [];
      const dateArray = dates.split(" - ");
      const startDate = head(dateArray);
      const endDate = last(dateArray);
      if (this.type == "date") {
        return [this.parseDate(startDate), this.parseDate(endDate)];
      }
    },
    parseDate(date) {
      if (date && date != "null") {
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      }
      return null;
    },
    formatDateYYYYMMDD(date) {
      const _date = new Date(date);
      return moment(_date).format("YYYY-MM-DD");
    },
    emitDates(date) {
      if (!date) return [];
      const start = head(date);
      const end = last(date);
      if (start && end) {
        let startDate = new Date(start).getTime();
        let endDate = new Date(end).getTime();

        if (startDate < endDate) {
          return [
            this.formatDateYYYYMMDD(startDate),
            this.formatDateYYYYMMDD(endDate),
          ];
        } else if (startDate > endDate) {
          return [
            this.formatDateYYYYMMDD(endDate),
            this.formatDateYYYYMMDD(startDate),
          ];
        } else {
          return [
            this.formatDateYYYYMMDD(startDate),
            this.formatDateYYYYMMDD(endDate),
          ];
        }
      }
      return [];
    },
    clearDate() {
      this.date = null;
      this.$emit("click:clear", true);
    },
    /* resetDate function is called from parent components  */
    resetDate() {
      this.date = [];
    },
    /* disablePastDates(val) {
			return this.disablePastFrom
				? val >= new Date(this.disablePastFrom).toISOString().substr(0, 10)
				: new Date();
		}, */
  },
  computed: {
    btxPlaceholder() {
      return this.placeholder + " [DD/MM/YYYY] - [DD/MM/YYYY]";
    },
    _id: {
      get() {
        return this.id ? (this.id || +new Date()).toString() : undefined;
      },
    },
  },
  mounted() {
    this.date = this.value;
  },
};
</script>
<style lang="scss">
.range-presets {
  list-style: none;
  li {
    padding: 4px 12px;
    border: 1px solid grey;
    border-radius: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    &:hover {
      background: rgba(var(--v-theme-primary), 0.2);
      border: 1px solid rgb(var(--v-theme-primary));
    }
  }
}
.date-preset-picker {
  .v-input.v-text-field {
    display: none;
  }
  .flatpickr-calendar {
    margin: 0;
    box-shadow: none;
    border-radius: 6px;
  }
  .flatpickr-calendar.inline {
    top: 0px;
  }
  .flatpickr-months {
    padding-block: 0.5rem;
    background: rgb(0 188 212/ 20%);
    .flatpickr-prev-month,
    .flatpickr-next-month {
      margin-block: 0;
    }
  }
}
.range-presets li:hover {
  background: rgb(0 188 212/ 20%);
  border: 1px solid #0cc2c2;
}
.range-presets li.active {
  background-color: cyan;
  color: #000;
}
.v-date-picker-header {
  background-color: #b9f9f9;
  border-bottom: 1px solid grey;
}
</style>
