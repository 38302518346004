var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.getFieldType == 'number')?[_c('v-text-field',_vm._b({class:{ 'error--text': _vm.isRequired && !_vm.textInputValue },on:{"keypress":($event) => _vm.validateNumber($event),"paste":($event) => _vm.pasteValidateNumber($event)},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}},'v-text-field',{
        ..._vm.$attrs,
      },false))]:(_vm.getFieldType == 'email')?[_c('v-text-field',_vm._b({class:{ 'error--text': _vm.isRequired && !_vm.textInputValue },attrs:{"append-icon":"mdi-email"},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}},'v-text-field',{
        ..._vm.$attrs,
      },false))]:(_vm.getFieldType == 'phone')?[_c('PhoneTextField',{on:{"blur":function($event){_vm.isFocusedN = false},"focus":function($event){_vm.isFocusedN = true}},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}})]:(_vm.getFieldType == 'date')?[_c('DatePicker',{attrs:{"solo":"","flat":"","default-date":_vm.textInputValue,"nullable":_vm.textInputValue ? false : true,"placeholder":_vm.placeholder},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}})]:(_vm.getFieldType == 'date-time')?[_c('DateTimePicker',{attrs:{"placeholder":_vm.placeholder},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}})]:(_vm.getFieldType == 'checkbox')?[_c('div',{class:[{ 'd-flex flex-wrap': _vm.show_in_one_row }]},_vm._l((_vm.getItems),function(item,index){return _c('div',{key:item.value + index,class:`py-1 px-0 col-sm-${_vm.show_in_one_row ? _vm.row_items : 12}`},[_c('v-checkbox',_vm._b({staticClass:"pa-0",attrs:{"label":item.value,"value":item.value,"color":"cyan"},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}},'v-checkbox',{ ..._vm.$attrs },false))],1)}),0)]:(_vm.getFieldType == 'radio')?[_c('v-radio-group',_vm._b({attrs:{"rules":[
        this.validateRules.required(
          _vm.textInputValue,
          _vm.getFieldType + ' default value'
        ),
      ]},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}},'v-radio-group',{ ..._vm.$attrs },false),[_c('div',{class:[{ 'd-flex flex-wrap': _vm.show_in_one_row }]},_vm._l((_vm.getItems),function(item,index){return _c('div',{key:item.value + index,class:`py-1 px-0 col-sm-${_vm.show_in_one_row ? _vm.row_items : 12}`},[_c('v-radio',{attrs:{"label":item.value,"value":item.value,"color":"cyan"}})],1)}),0)])]:(_vm.getFieldType == 'dropdown')?[_c('v-autocomplete',_vm._b({class:{ 'error--text': _vm.isRequired && !_vm.textInputValue },attrs:{"items":_vm.getItems,"item-value":"value","item-text":"value"},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}},'v-autocomplete',{
        ..._vm.$attrs,
      },false))]:(_vm.getFieldType == 'textarea')?[_c('TextAreaField',_vm._b({class:{ 'error--text': _vm.isRequired && !_vm.textInputValue },attrs:{"counter":_vm.getMax},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}},'TextAreaField',{
        ..._vm.$attrs,
      },false))]:(_vm.getFieldType == 'attachment')?[_c('FileInput',{attrs:{"validFileTypes":_vm.getValidFiles,"maxLimit":_vm.getMaxUploadLimit,"maxSize":_vm.getMaxUploadSize,"required":_vm.isRequired},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}})]:[_c('TextField',_vm._b({class:{ 'error--text': _vm.isRequired && !_vm.textInputValue },attrs:{"counter":_vm.getMax},model:{value:(_vm.textInputValue),callback:function ($$v) {_vm.textInputValue=$$v},expression:"textInputValue"}},'TextField',{
        ..._vm.$attrs,
      },false))]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }