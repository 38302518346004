<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid>
    <v-container>
      <v-row>
        <v-col md="3" v-if="type == 'customer'">
          <v-autocomplete
            class="ml-1"
            hide-details
            clearable
            v-model.trim="filter.property"
            :items="all_property"
            dense
            flat
            filled
            placeholder="Site Location"
            item-color="cyan"
            color="cyan"
            solo
            item-text="barcode"
            item-value="id"
            v-on:change="get_all_equipment()"
          >
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'No Site Location(s) Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
            <template v-slot:item="{ item }">
              <v-list-item-icon class="mr-3">
                <v-icon>mdi-home-map-marker</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="text-ellipsis max-width-400px">
                <v-list-item-title
                  class="text-capitalize font-weight-500 font-size-16"
                  >{{ item.barcode }} (
                  <template v-if="item.unit_no"> {{ item.unit_no }},</template>
                  <template v-if="item.street_1">
                    {{ item.street_1 }},</template
                  >
                  <template v-if="item.street_2">
                    {{ item.street_2 }},</template
                  >
                  <template v-if="item.zip_code">{{ item.street_2 }}</template
                  >)
                </v-list-item-title>
              </v-list-item-content>
            </template>
          </v-autocomplete>
          <!-- <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="new_equipment()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>New Equipment</span>
          </v-tooltip> -->
          <v-btn
            v-if="false"
            depressed
            :disabled="dataLoading"
            class="mx-2 custom-bold-button"
            v-on:click="EquipmentDialog = true"
            color="cyan white--text"
          >
            <v-icon dark left>mdi-plus</v-icon> Create
          </v-btn>
        </v-col>
        <v-col md="3" class="pl-0 d-flex">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            placeholder="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="get_all_equipment"
            @keydown.tab="get_all_equipment"
          ></v-text-field>

          <v-btn
            depressed
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            tile
            :disabled="dataLoading"
            :loading="dataLoading"
            v-on:click="get_all_equipment"
            style="height: 31px !important"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </v-col>
        <!-- <v-col md="2">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="get_all_equipment"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
          </v-col> -->
      </v-row>
    </v-container>
    <ListingTable
      :column-count="columnCount"
      :data-loading="dataLoading"
      :row-data="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" align="center" width="50">#</th>
            <th class="simple-table-th" align="center" width="100">Image</th>
            <th class="simple-table-th" v-if="!isCustomer" width="200">
              Customer
            </th>
            <th class="simple-table-th" width="100" v-if="type != 'property'">
              Site Location
            </th>
            <th class="simple-table-th" width="100">Name</th>
            <!--    <th class="simple-table-th" width="100">Description</th> -->
            <th class="simple-table-th" width="100">Serial No.</th>
            <!--  <th class="simple-table-th" width="100">Location</th> -->
            <!-- <th class="simple-table-th" width="100">Purchase Info</th> -->
            <th class="simple-table-th" width="100">Warranty Info</th>
            <th class="simple-table-th" width="100" v-if="false">
              Maintenance Info
            </th>

            <!--   <th class="simple-table-th" width="100">Equipment Other Info</th> -->
            <!--    <th class="simple-table-th" width="100">Created Time</th> -->
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length">
            <template v-for="(row, index) in dataRows">
              <v-hover :key="index" v-slot="{ hover }">
                <tr class="alternate-listing-row" height="80px" :key="index">
                  <td width="50" class="simple-table-td">
                    <Barcode
                      route="client-equipment.detail"
                      :barcode="row.barcode"
                      :id="row.id"
                    ></Barcode>
                  </td>
                  <td height="80px" class="simple-table-td" width="100">
                    <template v-if="row.image">
                      <v-img
                        class="customer-image"
                        width="70"
                        height="70"
                        contain
                        :lazy-src="$defaultImage"
                        :src="row.image"
                      >
                      </v-img>
                    </template>
                    <template v-else>
                      <v-img
                        class="customer-image"
                        width="70"
                        height="70"
                        contain
                        :lazy-src="$defaultImage"
                        :src="$defaultImage"
                      >
                      </v-img>
                    </template>
                  </td>
                  <td
                    height="80px"
                    v-if="!isCustomer"
                    class="simple-table-td"
                    width="200"
                  >
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Display Name: </b> {{ row.customer_display_name }}
                    </p>
                    <p class="m-0 custom-nowrap-ellipsis">
                      <b>Company: </b> {{ row.customer_company_name }}
                    </p>
                  </td>
                  <td
                    height="80px"
                    class="simple-table-td text-capitalize"
                    v-if="type != 'property'"
                  >
                    <template v-if="row?.address_property_name">
                      <b>
                        <v-icon
                          style="font-size: 18px; margin-top: -3px"
                          color="#000"
                        >
                          mdi-home-map-marker
                        </v-icon> </b
                      >{{ row?.address_property_name }},<br
                    /></template>

                    <template>
                      <b>
                        <v-icon
                          style="font-size: 18px; margin-top: -3px"
                          color="#000"
                        >
                          mdi-map-marker
                        </v-icon>
                      </b>
                      {{ row.address_unit_no }}
                      {{ row.address_street_1 }}
                      {{ row.address_street_2 }}
                      {{ row.address_country }}
                      {{ row.address_zip_code }}
                      <div>
                        {{ row?.location }}
                      </div>
                    </template>
                  </td>

                  <td
                    height="80px"
                    class="simple-table-td text-capitalize"
                    width="100"
                  >
                    <div>
                      {{ row.name }}
                    </div>

                    <template v-if="row.ticket > 0 && row.visit_barcode">
                      <Barcode
                        route="visit.detail"
                        :barcode="row.visit_barcode"
                        :id="row.visit_id"
                      ></Barcode>

                      <template v-if="row.visit_status == 4">
                        <v-chip
                          label
                          small
                          text-color="white"
                          color="green"
                          class="mt-1"
                        >
                          Complate</v-chip
                        >
                      </template>
                      <template v-else>
                        <v-chip
                          label
                          small
                          text-color="white"
                          color="red"
                          class="mt-1"
                        >
                          Pending</v-chip
                        >
                      </template>
                    </template>
                  </td>

                  <td height="80px" class="simple-table-td" width="100">
                    <template v-if="row.serial_no">
                      <div>
                        <v-chip
                          class="mb-1"
                          label
                          small
                          color="blue"
                          text-color="white"
                          >{{ row.serial_no }}</v-chip
                        >
                      </div>
                    </template>
                    <template v-else>
                      <em class="text-muted"> no serial no </em></template
                    >
                  </td>
                  <td height="80px" class="simple-table-td" width="100">
                    <div
                      class="warranty-listing-product"
                      v-if="
                        row && row.has_warranty > 0 && row.warranry_start_date
                      "
                    >
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Start Date </b>
                        <v-chip
                          outlined
                          text-color=""
                          class="mb-1"
                          label
                          small
                          color="green"
                          v-if="row.warranry_start_date"
                        >
                          {{ formatDate(row.warranry_start_date) }}
                        </v-chip>
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>End End &nbsp;&nbsp; </b>
                        <v-chip
                          outlined
                          text-color=""
                          class="mb-1"
                          label
                          small
                          color="red"
                          v-if="row.warranry_end_date"
                        >
                          {{ formatDate(row.warranry_end_date) }}
                        </v-chip>
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b
                          >Cost &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        </b>
                        <v-chip
                          outlined
                          class="mb-1"
                          text-color=""
                          label
                          small
                          color="blue"
                          v-if="row.warranry_cost"
                        >
                          {{ formatMoney(row.warranry_cost) }}
                        </v-chip>
                      </p>
                      <p class="m-0 custom-nowrap-ellipsis">
                        <b>Status &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </b>
                        <v-chip
                          small
                          label
                          text-color="white"
                          :color="getStatusColor(row.warranry_status)"
                          v-if="row.warranry_status"
                        >
                          {{ getStatusText(row.warranry_status) }}
                        </v-chip>
                      </p>
                    </div>
                    <em v-else class="text-muted"> no warranry</em>
                  </td>
                </tr>
              </v-hover>
            </template>
          </template>
          <tr v-else>
            <td :colspan="columnCount">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no customer assets at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
    <ListingFooter
      :dataLoading="dataLoading"
      :showingFrom="showingFrom"
      :showingTo="showingTo"
      :totalRows="totalRows"
      :currentPage="currentPage"
      :totalPages="totalPages"
    ></ListingFooter>

    <DeleteDialog :delete-dialog="delete_dailog">
      <template v-slot:title>Delete Confirmation</template>
      <template v-slot:text>
        <h3 class="font-size-16 font-weight-500 delete-text">
          Once you delete
          <span class="font-weight-700">{{ delete_row.name }}</span
          >, you won't be able to retrieve this later.<br />Are you sure you
          want to delete
          <span class="font-weight-700">{{ delete_row.name }}</span>
          ?
        </h3>
      </template>
      <template v-slot:action>
        <v-btn
          :disabled="delete_loading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="delete_dailog = false"
          >No! Cancel</v-btn
        >
        <v-btn
          :disabled="delete_loading"
          :loading="delete_loading"
          color="red lighten-1 white--text"
          v-on:click="delete_confirm()"
          class="mx-2 custom-grey-border custom-bold-button"
          >Yes! Delete</v-btn
        >
      </template>
    </DeleteDialog>
    <FullDialog
      v-if="EquipmentDialog"
      :dialog="EquipmentDialog"
      content-class="testdata"
    >
      <template v-slot:title
        ><!-- Customer
				<v-spacer></v-spacer>
				<v-btn class="text-white" color="red darken-4" depressed tile v-on:click="closeDialogRemark">
					Close
				</v-btn> -->
      </template>
      <template v-slot:body>
        <CreateEquipment
          v-if="EquipmentDialog"
          :customer-id="customerId"
          :is-customer="true"
          :is-create="true"
          v-on:update:warranty="get_all_equipment"
          v-on:close:warranty="closeDialogRemark"
        >
        </CreateEquipment>
      </template>
      <template v-slot:action>
        <v-btn
          class="text-white"
          color="red darken-4"
          depressed
          tile
          v-on:click="closeDialogRemark"
        >
          Close
        </v-btn>
        <v-btn depressed tile> </v-btn>
      </template>
    </FullDialog>
    <ManageBrand
      v-if="false"
      :b-dialog="manage_brand"
      :brand="brands"
      v-on:close-dialog="manage_brand = false"
      v-on:get-product-brand="get_options"
    ></ManageBrand>
    <ManageEquipmentLocation
      :dialog="manage_location"
      :equipment="equipment_locations"
      v-on:close-dialog="manage_location = false"
      v-on:get-equipment-location="get_options"
    ></ManageEquipmentLocation>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { QUERY, GET, POST, DELETE } from "@/core/services/store/request.module";
import CommonMixin from "@/core/plugins/common-mixin";
/* import Dialog from "@/view/pages/partials/Dialog.vue"; */
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import { ErrorEventBus } from "@/core/lib/message.lib";
// import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import { PaginationEventBus } from "@/core/lib/pagination.lib";
import FileManagerMixin from "@/core/lib/file-manager/file.manager.mixin";
import ValidationMixin from "@/core/plugins/validation-mixin";
import DeleteDialog from "@/view/pages/partials/Delete";
import ManageBrand from "@/view/pages/partials/Manage-Product-Brand.vue";
import ManageEquipmentLocation from "@/view/pages/partials/Manage-Equipment-Location.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import FullDialog from "@/view/components/FullDialog";
import { toSafeInteger } from "lodash";
import CreateEquipment from "@/view/pages/new-equipment/Create-New-Equipment.vue";
export default {
  mixins: [CommonMixin, FileManagerMixin, ValidationMixin],
  name: "equipment-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      warrantyDurationTypes: [
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" },
      ],
      dataRows: [],
      totalPages: 0,
      paginationPageSize: 10,
      currentPage: 1,
      totalRows: 0,
      rowsOffset: 0,
      dataLoading: true,
      manage_brand: false,
      manage_location: false,
      form_loading: false,
      image_loading: false,
      filter: {
        status: "all",
        search: null,
        property: null,
      },
      timeout: null,
      EquipmentDialog: false,
      timeoutLimit: 500,
      delete_dailog: false,
      delete_loading: false,
      delete_row: {},
      new_equipment_dailog: false,
      all_customers: [],
      all_property: [],
      brands: [],
      equipment_locations: [],
      filtered_property: [],
      equipments: [],
      equipment: {
        id: null,
        uuid: null,
        image: null,
        customer: null,
        property: null,
        name: null,
        serial_no: null,
        model: null,
        brand: null,
        location: null,
        warranty: 0,
        warranty_duration: null,
        warranty_type: null,
        description: "",
      },
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },

    customerId: {
      type: Number,
      default: 0,
    },
    propertyId: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    customerId(param) {
      this.equipment.customer = param;
    },
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.parent = this.lodash.toSafeInteger(param.id);
          if (this.parent > 0) {
            this.get_all_equipment();
          }
        }
      },
    },
  },
  components: {
    // TableActivity,
    /* Dialog, */
    ManageBrand,
    FullDialog,
    ManageEquipmentLocation,
    CreateEquipment,
    DeleteDialog,
    ListingFooter,
    ListingTable,
    Barcode,
  },
  methods: {
    getStatusText(data) {
      if (toSafeInteger(data) == 1) {
        return "Draft";
      } else if (toSafeInteger(data) == 2) {
        return "Active";
      } else if (toSafeInteger(data) == 3) {
        return "Extended";
      } else if (toSafeInteger(data) == 4) {
        return "Terminated";
      } else if (toSafeInteger(data) == 5) {
        return "Expired";
      }
    },
    getStatusColor(data) {
      if (toSafeInteger(data) == 1) {
        return "blue";
      } else if (toSafeInteger(data) == 2) {
        return "green";
      } else if (toSafeInteger(data) == 3) {
        return "orange";
      } else if (toSafeInteger(data) == 4) {
        return "red";
      } else if (toSafeInteger(data) == 5) {
        return "teal";
      }
    },
    detailWarrnty(param) {
      this.$router.push(
        this.getDefaultRoute("warranty.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    detailEquipmint(param) {
      this.$router.push(
        this.getDefaultRoute("equipment.detail", {
          params: {
            id: param,
          },
        })
      );
    },
    closeDialogRemark() {
      this.EquipmentDialog = false;
    },
    delete_confirm() {
      this.delete_loading = true;
      this.$store
        .dispatch(DELETE, {
          url: "equipment",
          data: { ids: [this.delete_row.id] },
        })
        .then(() => {
          this.delete_dailog = false;
          this.delete_row = {};
          this.get_all_equipment();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.delete_loading = false;
        });
    },
    open_delete_dailog(row) {
      this.delete_dailog = true;
      this.delete_row = row;
    },
    show_detail(row) {
      const brand = this.lodash.find(this.brands, { text: row.brand });

      const location = this.lodash.find(this.equipment_locations, {
        text: row.location,
      });

      const equipment = {
        id: row.id,
        uuid: uuidv4(),
        image: row.image,
        customer: row.customer,
        property: row.property,
        name: row.name,
        serial_no: row.serial_no,
        model: row.model,
        brand: brand ? brand.id : 0,
        location: location ? location.id : 0,
        warranty: row.warranty,
        warranty_duration: row.warranty_duration,
        warranty_type: row.warranty_type,
        description: row.description,
      };

      this.equipment = this.lodash.cloneDeep(equipment);
      // this.equipments.push(equipment);
      this.new_equipment_dailog = true;
      this.$nextTick(() => {
        this.filter_property();
      });
    },
    remove_row(index) {
      this.equipments.splice(index, 1);
    },
    updateIntRow(row) {
      this.equipment = this.lodash.cloneDeep(row);
    },
    getCustomer(id, key) {
      const customer = this.lodash.find(this.all_customers, { id });
      if (customer) {
        return customer[key];
      }
      return "N/A";
    },
    getAddress(id, key) {
      const property = this.lodash.find(this.all_property, { id });
      if (property) {
        return property[key];
      }
      return "N/A";
    },
    getBrand(id) {
      const brand = this.lodash.find(this.brands, { id });
      if (brand) {
        return brand.text;
      }
      return "N/A";
    },
    getLocation(id) {
      const location = this.lodash.find(this.equipment_locations, { id });
      if (location) {
        return location.text;
      }
      return "N/A";
    },
    cancel_update() {
      this.equipment = new Object({
        id: null,
        uuid: null,
        image: null,
        customer: null,
        property: null,
        name: null,
        serial_no: null,
        model: null,
        brand: null,
        location: null,
        warranty: 0,
        warranty_duration: null,
        warranty_type: null,
      });

      if (this.customerId) {
        this.equipment.customer = this.customerId;
      }
    },
    add_equipment() {
      if (!this.$refs.equipmentForm.validate()) {
        return false;
      }

      const equipmentIndex = this.lodash.findIndex(this.equipments, {
        uuid: this.equipment.uuid,
      });
      if (equipmentIndex >= 0) {
        this.equipments.splice(equipmentIndex, 1, this.equipment);
      } else {
        this.equipment.uuid = uuidv4();
        this.equipments.push(this.equipment);
      }

      this.$nextTick(() => {
        this.equipment = new Object({
          id: null,
          uuid: null,
          image: null,
          customer: null,
          property: null,
          name: null,
          serial_no: null,
          model: null,
          brand: null,
          location: null,
          warranty: 0,
          warranty_duration: null,
          warranty_type: null,
        });

        if (this.customerId) {
          this.equipment.customer = this.customerId;
        }
      });
    },
    updateOrCreate() {
      if (this.form_loading) {
        return false;
      }

      if (!this.equipments.length) {
        ErrorEventBus.$emit(
          "update:error",
          "Please add atleast one equipment."
        );
        return false;
      }

      this.form_loading = true;

      this.$store
        .dispatch(POST, {
          url: "equipment",
          data: { equipments: this.equipments },
        })
        .then(() => {
          this.closeDialog();
          this.get_all_equipment();
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.form_loading = false;
        });
    },
    closeDialog() {
      this.new_equipment_dailog = false;
      this.equipments = [];
      this.equipment = new Object({
        id: null,
        uuid: null,
        image: null,
        customer: null,
        property: null,
        name: null,
        serial_no: null,
        model: null,
        brand: null,
        location: null,
        warranty: 0,
        warranty_duration: null,
        warranty_type: null,
      });
      if (this.customerId) {
        this.equipment.customer = this.customerId;
      }
    },
    select_image() {
      this.$refs["uploadImage"].$refs["input"].click();
    },
    upload_image(param) {
      const _this = this;
      _this.image_loading = true;
      _this
        .uploadAttachment(param)
        .then((response) => {
          const image = _this.lodash.head(response);
          _this.equipment.image = image.file ? image.file.url : null;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.image_loading = false;
        });
    },
    get_formatted_property(row) {
      let tempArr = [];
      if (row.unit_no) {
        tempArr.push(row.unit_no);
      }
      if (row.street_1) {
        tempArr.push(row.street_1);
      }
      if (row.street_2) {
        tempArr.push(row.street_2);
      }
      if (row.zip_code) {
        tempArr.push(row.zip_code);
      }
      if (row.country_name) {
        tempArr.push(row.country_name);
      }
      return tempArr.join(", ");
    },
    new_equipment() {
      this.new_equipment_dailog = true;
      this.filter_property();
    },
    filter_property() {
      this.filtered_property = this.lodash.filter(this.all_property, {
        customer: this.equipment.customer,
      });
    },
    get_options() {
      this.$store
        .dispatch(GET, { url: "equipment/options" })
        .then(({ data }) => {
          this.all_customers = data.customers;
          this.all_property = data.properties;
          this.brands = data.brands;
          this.equipment_locations = data.equipment_locations;
          this.filtered_property = this.lodash.filter(this.all_property, {
            customer: this.equipment.customer,
          });
        })
        .catch((error) => {
          this.logError(error);
        });
    },

    get_property() {
      this.$store
        .dispatch(QUERY, {
          url: `property/customer/${this.customerId}`,
          data: { search: this.search, status: "all" },
        })
        .then(({ data }) => {
          this.all_property = data.rows;
        })
        .catch((error) => {
          this.logError(error);
        });
    },
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.get_all_equipment();
    },
    get_all_equipment() {
      const _this = this;

      let filter = {
        status: "all",
        per_page: _this.paginationPageSize,
        current_page: _this.currentPage,
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
        property: _this.filter.property,
      };

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "equipment",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.rows;
            _this.totalPages = Math.ceil(data.total_rows / data.per_page);
            _this.currentPage = data.current_page;
            _this.totalRows = data.total_rows;
            _this.rowsOffset = data.offset;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
  mounted() {
    const _this = this;

    /*     _this.get_options(); */
    _this.get_property();

    PaginationEventBus.$on("update:pagination", (param) => {
      _this.currentPage = param;
      _this.get_all_equipment();
    });

    _this.$nextTick(() => {
      _this.equipment.customer = _this.customerId;
    });
  },
  computed: {
    dialogWidth() {
      return this.lodash.toSafeInteger((document.body.clientWidth / 100) * 70);
    },
    columnCount() {
      let result = 9;
      if (this.isCustomer) {
        --result;
      }
      return result;
    },
    showingFrom() {
      return this.lodash.isEmpty(this.dataRows) === false
        ? this.rowsOffset + 1
        : 0;
    },
    showingTo() {
      return this.rowsOffset + (this.dataRows ? this.dataRows.length : 0);
    },
  },
};
</script>
