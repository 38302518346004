<template>
  <!--begin::Product Internal Listing-->
  <v-container fluid class="pt-0">
    <v-container class="p-0 m-0">
      <v-row>
        <v-col cols="12">
          <v-flex class="d-flex font-level-3-bold my-auto">
            <span class="detail-svg-icon mr-2">
              <!--begin::Svg Icon-->
              <span class="svg-icon svg-icon-lg mr-2 svg-v-list-icon">
                <inline-svg :src="$assetURL('media/custom-svg/product.svg')" />
              </span>
              <!--end::Svg Icon-->
            </span>
            <h1 class="form-title font-size-20 font-weight-700">
              Products / Parts
            </h1>
          </v-flex>
        </v-col>
        <!--         <v-col md="3">
          <v-text-field
            v-model.trim="filter.search"
            v-on:click:clear="clearFilter('search')"
            clearable
            dense
            filled
            flat
            label="Search"
            hide-details
            solo
            :disabled="dataLoading"
            :loading="dataLoading"
            color="cyan"
            @keydown.enter="getAllProducts"
            @keydown.tab="getAllProducts"
          ></v-text-field>
        </v-col> -->

        <!-- <v-col md="3">
          <v-tooltip top content-class="custom-top-tooltip">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                class="mx-2 custom-bold-button white--text"
                color="cyan"
                tile
                :disabled="dataLoading"
                :loading="dataLoading"
                v-on:click="getAllProducts"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </template>
            <span>Search</span>
          </v-tooltip>
        </v-col> -->
      </v-row>
    </v-container>
    <ListingTable
      :columnCount="3"
      :dataLoading="dataLoading"
      :rowData="dataRows"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th class="simple-table-th" width="100">#</th>
            <th class="simple-table-th" width="200">Info</th>
            <th class="simple-table-th" width="200">Price</th>
            <!-- <th class="simple-table-th" width="200">Create Time</th> -->
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody class="custom-border-bottom custom-border-top">
          <template v-if="dataRows.length > 0">
            <tr
              :class="{
                'custom-border-top': index == 0,
                'orange_lighten-5': index % 2 == 0,
              }"
              link
              :key="index"
              v-for="(row, index) in dataRows"
              v-on:click="
                $router.push(
                  getDefaultRoute('product.detail', {
                    params: { id: row.product_id },
                  })
                )
              "
            >
              <td class="simple-table-td">
                <Barcode
                  route="service.detail"
                  :barcode="row.product_barcode"
                  :id="row.id"
                ></Barcode>
              </td>
              <td class="simple-table-td">
                <div class="product-listing-customer">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Name: </b> {{ row.product_name }}
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Part Number: </b>
                    <template v-if="row.products_part_number">{{
                      row.products_part_number
                    }}</template>
                    <template v-else
                      ><em class="text--secondary">No Part Number</em></template
                    >
                  </p>
                </div>
              </td>

              <td class="simple-table-td" width="100">
                <div class="product-listing-invoice">
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Purchasing Price: </b>
                    <template v-if="row.supplier_price">{{
                      $accountingJS.formatMoney(row.supplier_price)
                    }}</template>
                    <template v-else><span> $ 0.00</span></template>
                  </p>
                  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Supplier Code: </b>
                    <template v-if="row.part_number">{{
                      row.part_number
                    }}</template>
                    <template v-else><span>no supplier code</span></template>
                  </p>

                  <!--  <p class="m-0 custom-nowrap-ellipsis">
                    <b>Charges: </b>
                    <template v-if="row.charges">{{ row.charges }}</template>
                    <template v-else
                      ><em class="text--secondary"
                        >No Selling Price</em
                      ></template
                    >
                  </p> -->
                </div>
              </td>
              <!-- <td>
                   <TableActivity
                            v-if="!lodash.isEmpty(row.added_by)"
                            :row="row"
                          >
                            <template v-slot:display_name>
                              {{ row.created_by }}
                            </template>
                            <template v-slot:date_time>
                              {{ row.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(row.added_at) }}
                              {{ formatedateTime(row.added_at) }}
                            </template>
                          </TableActivity>
</td> -->
            </tr>
          </template>
          <tr v-else-if="!dataLoading">
            <td colspan="3">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no Product at the moment.
              </p>
            </td>
          </tr>
        </tbody>
      </template>
    </ListingTable>
  </v-container>
  <!--end::Product Internal Listing-->
</template>

<script>
import moment from "moment-timezone";
import { QUERY } from "@/core/services/store/request.module";
//import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import Barcode from "@/view/pages/partials/Barcode.vue";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import { ErrorEventBus, InitializeError } from "@/core/lib/message.lib";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  mixins: [CommonMixin],
  name: "product-internal-list",
  data() {
    return {
      parent: 0,
      dates: [],
      statusList: [],
      dataRows: [],
      dataLoading: true,
      customerList: [],
      filter: {
        status: "all",
        search: null,
      },
      timeout: null,
      timeoutLimit: 500,
    };
  },
  props: {
    detail: {
      type: Object,
      required: true,
      default: () => {
        return new Object();
      },
    },
    type: {
      type: String,
      required: true,
      default: "customer",
    },
  },
  watch: {
    detail: {
      deep: true,
      immediate: true,
      handler(param) {
        if (this.lodash.isEmpty(param) === false) {
          this.parent = this.lodash.toSafeInteger(param.id);
          if (this.parent > 0) {
            this.getAllProducts();
          }
        }
      },
    },
  },
  components: {
    Barcode,
    ListingTable,
    // TableActivity,
  },
  methods: {
    clearFilter(field) {
      const _this = this;
      switch (field) {
        case "search":
          _this.filter.search = null;
          break;
        case "dates":
          _this.dates = [];
          break;
      }
      _this.getAllProducts();
    },
    getAllProducts() {
      const _this = this;

      let filter = {
        per_page: 10,
        current_page: 1,
        status: _this.filter.status || "all",
        search: _this.filter.search || undefined,
        dates: _this.dates || undefined,
        type: _this.type,
        type_id: _this.parent,
      };
      if (_this.lodash.isEmpty(_this.dates) === false) {
        if (moment(_this.dates[0]).isAfter(_this.dates[1])) {
          ErrorEventBus.$emit(
            "update:error",
            InitializeError("End Date must be after Start Date.")
          );
          return false;
        }
      } else {
        filter.dates = undefined;
      }

      clearTimeout(_this.timeout);
      _this.dataRows = [];
      _this.dataLoading = true;
      _this.timeout = setTimeout(function () {
        _this.$store
          .dispatch(QUERY, {
            url: "product/transaction",
            data: filter,
          })
          .then(({ data }) => {
            _this.dataRows = data.tbody;
            _this.statusList = data.status_list;
          })
          .catch((error) => {
            _this.logError(error);
          })
          .finally(() => {
            _this.dataLoading = false;
          });
      }, _this.timeoutLimit);
    },
  },
};
</script>
