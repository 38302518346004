<template>
  <v-radio-group
    dense
    mandatory
    class="custom-radio-group"
    :class="customClass"
    id="id"
    v-model="radioinput"
    :row="row"
    v-on:change="$emit('change', radioinput)"
    :hide-details="hideDetails"
  >
    <div
      v-for="(item, index) in items"
      :key="index"
      class="pa-1 rounded-sm custom-radio-input mt-0"
      :class="{
        active: item.value == radioinput,
      }"
    >
      <v-radio
        :color="item.color ? item.color : color"
        :disabled="disabled"
        :value="item.value"
      >
        <template #label>
          <img v-if="item.img" style="max-width: 25px" :src="item.img" alt="" />
          {{ item.label }}
          <div
            class="selected text-h5 text-white"
            :class="{
              active: item.value == radioinput,
            }"
          >
            Selected
          </div>
        </template>
      </v-radio>
    </div>
  </v-radio-group>
</template>
<script>
export default {
  name: "radio-input",
  title: "Radio Input",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: [Boolean, Number, String],
      default: null,
    },
    items: {
      type: Array,
      required: true,
      default: () => {
        return [];
      },
    },
    id: {
      type: String,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: "mt-3 pt-0",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    row: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(param) {
        this.radioinput = param;
      },
    },
  },
  data() {
    return {
      radioinput: null,
    };
  },
  mounted() {
    this.radioinput = this.value;
  },
};
</script>
<style lang="scss">
.custom-radio-group .v-input--radio-group__input {
  grid-gap: 8px;
}
.custom-radio-input {
  border: 1px solid grey;
  flex-grow: 1;
  &.active {
    border-color: #00248a !important;
    background: #00248a14 !important;
    // background: #00248a14 !important;
  }
}
.rating-radio .custom-radio-input.active {
  border: 3px solid #ff9800 !important;
}
.rating-radio .custom-radio-input .v-input--selection-controls__input {
  z-index: 99;
  /* margin-right: 8px; */
  position: absolute;
  top: 15px;
}

.rating-radio .custom-radio-input {
  border: 3px solid rgb(228, 224, 224);
  overflow: hidden;
}
.rating-radio .custom-radio-input img {
  width: 100%;
  max-width: 100% !important;
}
.selected {
  display: none;
}
.rating-radio .selected.active {
  display: initial !important;
  position: absolute;
  bottom: -29px;
  background: #ff9800;
  text-align: center;
  padding: 3px 50px;
  right: -86px;
  height: 80px;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  overflow: hidden;
}
</style>
