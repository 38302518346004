<template>
  <v-container fluid>
    <ListingTable
      :columnCount="5"
      :dataLoading="dataLoading"
      :rowData="warranty"
    >
      <template v-slot:thead>
        <thead>
          <tr>
            <th width="100" class="simple-table-th">#</th>
            <th class="simple-table-th">Service Form</th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody v-if="serviceFormList.length > 0">
          <tr v-for="(row, index) in serviceFormList" :key="index">
            <td width="100" class="simple-table-td">{{ index + 1 }}.</td>
            <td class="simple-table-td">{{ row.label }}</td>
          </tr>
        </tbody>
        <tfoot v-else>
          <tr>
            <td colspan="2" class="py-4">
              <p
                class="m-0 row-not-found text-center font-weight-500 font-size-16"
              >
                <img
                  :src="$assetURL('media/error/empty.png')"
                  class="row-not-found-image"
                />
                Uhh... There are no service form assigned at the moment.
              </p>
            </td>
          </tr>
        </tfoot>
      </template>
    </ListingTable>
    <Dialog :commonDialog="dialog" :dialogWidth="768">
      <template v-slot:title>Service Forms</template>
      <template v-slot:body>
        <v-form
          ref="serviceForm"
          v-model.trim="formValid"
          lazy-validation
          v-on:submit.stop.prevent="onSubmit"
        >
          <v-autocomplete
            v-model.trim="customerServiceForms"
            :items="serviceForms"
            dense
            filled
            placeholder="Select Service Form"
            item-color="cyan"
            color="cyan"
            solo
            flat
            multiple
            :disabled="pageLoading || formLoading"
            item-text="label"
            item-value="id"
            :rules="[
              validateRules.required(customerServiceForms, 'Service Form'),
            ]"
          >
            <template v-slot:append-outer>
              <v-tooltip top content-class="custom-top-tooltip">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon class="mx-2" v-bind="attrs" v-on="on"
                    >mdi-progress-question</v-icon
                  >
                </template>
                <span
                  >Select service form which you<br />want to assign for this
                  customer</span
                >
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-list-item>
                <v-list-item-title
                  v-html="'Nothing Found.'"
                ></v-list-item-title>
              </v-list-item>
            </template>
          </v-autocomplete>
        </v-form>
      </template>
      <template v-slot:action>
        <template v-if="getPermission('customer:update')">
          <v-btn
            :disabled="pageLoading || !formValid || formLoading"
            :loading="formLoading"
            class="mx-2 custom-bold-button white--text"
            color="cyan"
            v-on:click="onSubmit()"
          >
            Update
          </v-btn>
        </template>
        <v-btn
          :disabled="formLoading"
          class="mx-2 custom-grey-border custom-bold-button"
          v-on:click="$emit('close:dialog')"
          >Close
        </v-btn>
      </template>
    </Dialog>
  </v-container>
</template>
<script>
import Dialog from "@/view/pages/partials/Dialog.vue";
import { QUERY, POST } from "@/core/services/store/request.module";
import ValidationMixin from "@/core/plugins/validation-mixin";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
export default {
  mixins: [ValidationMixin],
  props: {
    customer: {
      type: Number,
      required: true,
      default: 0,
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      formValid: true,
      pageLoading: true,
      formLoading: false,
      serviceFormList: [],
      serviceForms: [],
      customerServiceForms: [],
    };
  },
  watch: {
    customer(param) {
      if (param > 0) {
        this.getServiceForms(param);
      }
    },
  },
  methods: {
    onSubmit() {
      const _this = this;
      if (!_this.$refs.serviceForm.validate()) {
        return false;
      }
      _this.formLoading = true;

      _this.$store
        .dispatch(POST, {
          url: "customer/service-forms",
          data: {
            customer: _this.customer,
            service_form: _this.customerServiceForms,
          },
        })
        .then(({ data }) => {
          _this.$emit("close:dialog");
          _this.getServiceForms(_this.customer);
          _this.serviceForms = data.service_forms;
          _this.customerServiceForms = data.customer_service_forms;
          _this.serviceFormList = data.customer_service_forms;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.formLoading = false;
        });
    },
    getServiceForms(customer) {
      const _this = this;
      _this.$store
        .dispatch(QUERY, {
          url: "customer/service-forms",
          data: { customer },
        })
        .then(({ data }) => {
          _this.serviceForms = data.service_forms;
          _this.customerServiceForms = data.customer_service_forms;
          _this.serviceFormList = data.customer_service_forms;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  components: {
    Dialog,
    ListingTable,
  },
  mounted() {
    if (this.customer > 0) {
      this.getServiceForms(this.customer);
    }
  },
};
</script>
