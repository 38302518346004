<template>
  <div class="d-inline-block">
    <v-icon class="mr-2" @click.prevent.stop="showBarcode()"
      >mdi-barcode-scan</v-icon
    >
    <Dialog :dialog="dialog" :dialog-width="450" @close="closeDialog">
      <template v-slot:title>
        <div class="d-flex w-100">
          <span class="text-capitalize">
            {{ getTitle }}
          </span>
          <v-spacer></v-spacer>
          <v-icon color="#000" size="30" @click="closeDialog">mdi-close</v-icon>
        </div>
      </template>
      <template v-slot:body>
        <div class="mb-5 mt-4">
          <img
            v-if="getBarcodeUrl !== ''"
            width="100%"
            :src="getBarcodeUrl"
            alt=""
          />
          <div class="show-barcode-text text-capitalize">{{ barcodeText }}</div>
        </div>
        <!--  <span class="font-weight-700">
            {{ getTitle }} 
         </span> -->
      </template>
    </Dialog>
  </div>
</template>
<script>
import Dialog from "@/view/components/Dialog";
export default {
  name: "ShowBarcode",
  data() {
    return {
      dialog: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Barcode",
    },
    barcodeText: {
      type: String,
      default: null,
    },
    barcodeUrl: {
      type: String,
      default:
        "https://ozbarcodes.com.au/wp-content/uploads/edd/2022/11/EAN-13-Barcode_SAMPLE-2.jpg",
    },
  },
  methods: {
    showBarcode() {
      this.dialog = true;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
  computed: {
    getTitle() {
      return this.title;
    },
    getBarcodeUrl() {
      return this.barcodeUrl;
    },
  },
  components: {
    Dialog,
  },
};
</script>
